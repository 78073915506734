import { Injectable, inject } from '@angular/core';
import { TooltipsApiService } from '@core/api/tooltip-api.service';
import {
  AppInitializeAction,
  FleetApiActions,
  SetTooltipAction,
  SetVariablesDataAction,
} from '@dashboard/dashboard-store/actions';
import { SystemDataErrorsService } from '@dashboard/services/system-data-errors.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap, of, switchMap, tap } from 'rxjs';

@Injectable()
export class SystemEffects {
  private actions$ = inject(Actions);
  private SystemDataError = inject(SystemDataErrorsService);
  private TooltipsApi = inject(TooltipsApiService);

  //this runs only once on init app when FleetApiActions.success() is dispatched
  GetTooltip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppInitializeAction),
      mergeMap(() =>
        this.TooltipsApi.getTooltip().pipe(
          catchError((error) => {
            console.error('Error fetching tooltip:', error);
            return EMPTY;
          })
        )
      ),
      switchMap(({ value }) => {
        return of(SetTooltipAction({ value }));
      })
    );
  });
  IngestionErrorCheck$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FleetApiActions.success),
        map(() => {
          setTimeout(() => {
            this.SystemDataError.IngestionErrorChecker();
          }, 200);
        })
      );
    },
    { dispatch: false }
  );
  ///When ws message run the process to check if the vessel is offline
  RunSystemErrorCheck$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SetVariablesDataAction),
        map((action) => {
          if (action.source === 'WS') {
            setTimeout(() => {
              this.SystemDataError.IngestionErrorChecker();
            }, 100);
          }
        })
      );
    },
    { dispatch: false }
  );
}
