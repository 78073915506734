import { Injectable, inject } from '@angular/core';
import {
  ActiveTraceConfigSelector,
  AddNewTracePointAction,
  ToDateSelector,
  SelectedVesselImo,
  SetActiveTraceAction,
  SetSelectedVesselAction,
  SetSystemDatesAction,
  SetVariablesDataAction,
  SetVariablesPayloadType,
  TraceSelector,
  UpdateActiveTrace,
  VesselDataSelector,
  FromDateSelector,
} from '@dashboard-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { TraceService } from '@dashboard/models/trace/trace.service';
import { TypedAction } from '@ngrx/store/src/models';
import {
  NaviqoreDataApiService,
  ExportGraphRequest,
} from '@core/api/naviqore-data-api.service';
import { TransformTagToId } from '@dashboard/tags-to-ids.transformer';
import { TimelineService } from '@dashboard/components/map-timeline/timeline.service';
import { QueryDataDates } from '@core/constants/time.defaults';
import { TraceApiActions } from '../actions/trace.actions';

@Injectable()
export class TraceEffects {
  private actions$ = inject(Actions);
  private _store = inject(Store);
  private _traceService = inject(TraceService);
  private _timeline = inject(TimelineService);
  private _queryDataApi = inject(NaviqoreDataApiService);

  trigerTraceApiCall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SetActiveTraceAction,
        SetSelectedVesselAction,
        SetSystemDatesAction
      ),

      mergeMap(() => {
        return of(TraceApiActions.call());
      })
    );
  });

  traceRequestsSet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TraceApiActions.call),
      withLatestFrom(
        this._store.select(ActiveTraceConfigSelector),
        this._store.select(ToDateSelector),
        this._store.select(FromDateSelector),
        this._store.select(SelectedVesselImo)
      ),
      mergeMap(([, config, toDate, fromDate, selectedImo]) => {
        //If selected vessel change and is not set return easly
        if (!selectedImo) return EMPTY;

        const traceConfig = config;

        const req: ExportGraphRequest = {
          start: QueryDataDates(fromDate),
          end: QueryDataDates(toDate),
          variables: [
            { tagId: Number(TransformTagToId(traceConfig.templateTag)) },
            { tagId: Number(TransformTagToId(traceConfig.traceTag)) },
          ],
          filters: [],
        };

        return this._queryDataApi.getExportGraphApiData(selectedImo, req).pipe(
          switchMap((traceResponse) => {
            if ('data' in traceResponse) {
              const traceRespData = traceResponse.data;

              const templateRespData = traceRespData[0].tagData as string[];
              const coordData = traceRespData[1].tagData as string[];

              const { traceData, relativeCustomRange } =
                this._traceService.UpdateDashboardLine(
                  traceConfig,
                  coordData || [],
                  templateRespData || []
                );

              const variablePayload: SetVariablesPayloadType = [
                selectedImo,
                'trace',
                {
                  values: traceData,
                  variableId: 'trace',
                  timestamp: 0,
                  formattedValue: '',
                  notInSync: null,
                },
              ];

              const actionsArray: TypedAction<any>[] = [];
              if (variablePayload) {
                actionsArray.push(
                  SetVariablesDataAction({
                    variableMeta: [variablePayload],
                  })
                );
              }
              //Ralative Range because for each sleected tag we need to update the color range coding at the bottom right
              if (relativeCustomRange && relativeCustomRange.length > 0) {
                actionsArray.push(
                  UpdateActiveTrace({
                    traceConfig: {
                      ...config,
                      range: relativeCustomRange,
                    },
                  })
                );
              }
              return of(...actionsArray, TraceApiActions.success());
            }
            return EMPTY;
          }),
          catchError((error) => {
            const variablePayload: SetVariablesPayloadType = [
              selectedImo,
              'trace',
              {
                values: [],
                variableId: 'trace',
                timestamp: 0,
                formattedValue: '',
                notInSync: null,
              },
            ];

            console.log({ variablePayload });
            return of(
              SetVariablesDataAction({
                variableMeta: [variablePayload],
              }),
              TraceApiActions.failure()
            );
          })
        );
      })
    );
  });

  //We need this to happens only when its from websockets.
  ExtendActiveTrace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetVariablesDataAction),
      withLatestFrom(
        this._store.select(ActiveTraceConfigSelector),
        this._store.select(SelectedVesselImo),
        this._store.select(TraceSelector()),
        this._store.select(VesselDataSelector())
      ),
      mergeMap(([action, activeConfig, imo, , activeImoData]) => {
        if (!imo) return EMPTY;
        if (action.source === 'API') return EMPTY;

        const { variableMeta } = action;

        const CoordForActiveTrace = variableMeta.filter(
          ([toFindImo, tag]) => toFindImo === imo && tag === 'COORD'
        );
        const traceTemplateTag = activeConfig.templateTag;
        const TemplateValue = activeImoData[traceTemplateTag].formattedValue;

        if (CoordForActiveTrace.length === 0) return EMPTY;

        const [actionData] = CoordForActiveTrace;
        const [, , position] = actionData;

        const newPosition = this._traceService.addNewValueInLine(activeConfig, {
          position: position.formattedValue,
          template: TemplateValue,
        });

        return of(
          AddNewTracePointAction({
            position: newPosition,
            selectedImo: imo,
          })
        );
      })
    );
  });
}
