import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
@Injectable()
export class WsEffects {
  private actions$ = inject(Actions);
  private _store = inject(Store);

  // HandleRealtimeFlag$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(SetSystemDatesAction),
  //     switchMap(({ toDate }) => {
  //       const isRealTime = TimestampIsInCurrentSecond(toDate);
  //       console.log({ isRealTime, toDate });
  //       return of(SetRealtimeFlagAction({ enableRealtime: isRealTime }));
  //     })
  //   );
  // });
}
