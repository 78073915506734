import { StoreDashboardState } from '@dashboard/dashboard-store/dashboard.state';
import {
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  defaultMemoize,
  MemoizedSelector,
} from '@ngrx/store';
import { isEqual } from 'lodash';

const dataState = createFeatureSelector<StoreDashboardState>('dashboard');
export const DeepSelector = createSelectorFactory((projectionFn) =>
  defaultMemoize(
    projectionFn,
    arraysAndObjectsComparison,
    arraysAndObjectsComparison
  )
) as typeof createSelector;
function arraysAndObjectsComparison(a: any, b: any): boolean {
  const res = isEqual(a, b);
  return res;
}

const VesselsHasError = DeepSelector(
  dataState,
  (dataState: StoreDashboardState) => {
    const vesselsData = Object.values(dataState.data);

    return (
      vesselsData.filter(
        (v) => Boolean(v.ingestion_error.formattedValue) === true
      ).length > 0
    );
  }
) as MemoizedSelector<StoreDashboardState, boolean>;

const IngestionErrorEvaluationData = createSelector(dataState, (dataState) => {
  const vesselsImo = Object.keys(dataState.data);
  const vesselsData = Object.values(dataState.data);
  return vesselsData.map((v, idx) => ({
    imo: vesselsImo[idx],
    latest_timestamp: Number(v.latest_timestamp.formattedValue),
    systemHealth: v.SYSTEM_HEALTH.formattedValue,
  }));
});

export { VesselsHasError, IngestionErrorEvaluationData };
