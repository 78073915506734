import {
  createSelector,
  createFeatureSelector,
  createSelectorFactory,
  defaultMemoize,
} from '@ngrx/store';
import { IStoreCourseState } from '@shared/modules/course-planning/store/course-state.model';
import { isEqual } from 'lodash';

export const DeepSelector = createSelectorFactory((projectionFn) =>
  defaultMemoize(
    projectionFn,
    arraysAndObjectsComparison,
    arraysAndObjectsComparison
  )
) as typeof createSelector;

function arraysAndObjectsComparison(a: any, b: any): boolean {
  const res = isEqual(a, b);
  return res;
}
const UiState = createFeatureSelector<IStoreCourseState>('course');

const mapWaypointsSelector = DeepSelector(UiState, (dataState) => {
  return dataState.waypoints;
});

const WaypointEditingStateSelector = createSelector(UiState, (dataState) => {
  return dataState.waypointEditing;
});
const courseMileSelector = createSelector(UiState, (dataState) => {
  return dataState.calculatedMiles;
});
const CourseLoadingSelector = createSelector(UiState, (dataState) => {
  return dataState.loading;
});
const CourseResultsSelector = DeepSelector(UiState, (dataState) => {
  return dataState.results;
});

const FromCurrSelector = DeepSelector(UiState, (dataState) => {
  return dataState.fromCurr;
});

const CourseResultsRoutesSelector = DeepSelector(UiState, (dataState) => {
  return dataState.results.segments;
});

const CourseResultsSummarySelector = DeepSelector(UiState, (dataState) => {
  return dataState.results.summary;
});

export {
  mapWaypointsSelector,
  courseMileSelector,
  WaypointEditingStateSelector,
  CourseLoadingSelector,
  CourseResultsSelector,
  CourseResultsRoutesSelector,
  FromCurrSelector,
  CourseResultsSummarySelector,
};
