import {
  LayersBtn,
  WindyTypes,
} from '@dashboard/dashboard-store/dashboard.state';
import { createAction, props } from '@ngrx/store';
import { LineMeta } from '@widgets/map-widget/services/map-api.service';

enum MapLayerActions {
  SET_MAP_LAYERS_CONFIG = '[INIT] SET_MAP_LAYERS_CONFIG',
  SET_BASEMAP_ACTIVE_LAYER = '[DASHBOARD UI STATE] SET_BASEMAP_ACTIVE_LAYER',
  SET_OVERLAY_ACTIVE_LAYER = '[DASHBOARD UI STATE] SET_OVERLAY_ACTIVE_LAYER',
  EMIT_MAP_ZOOM_VALUE = '[DASHBOARD UI STATE] EMIT_MAP_ZOOM_VALUE',
  MAP_CLICKED_LAT_LNG = '[DASHBOARD UI STATE] MAP_CLICKED_LAT_LNG',
  MAP_CLICK_ACTION = '[DASHBOARD UI STATE] MAP_CLICK_ACTION',
  MARKER_CLICK_ACTION = '[DASHBOARD UI STATE] MARKER_CLICK_ACTION',
  MARKER_DRAG_ACTION = '[DASHBOARD UI STATE] MARKER_DRAG_ACTION',
  LINE_CLICK_ACTION = '[DASHBOARD UI STATE] LINE_CLICK_ACTION',
  SET_WINDY_LAYER = '[DASHBOARD UI STATE] SET_WINDY_LAYER',
}
const SetMapLayersConfigAction = createAction(
  MapLayerActions.SET_MAP_LAYERS_CONFIG,
  props<{
    baseMaplayers: LayersBtn;
    overlayLayers: LayersBtn;
  }>()
);
const SetActiveBaseMapLayer = createAction(
  MapLayerActions.SET_BASEMAP_ACTIVE_LAYER,
  props<{ layerId: string }>()
);
const ToggleWindyLayerAction = createAction(
  MapLayerActions.SET_WINDY_LAYER,
  props<{ windyType: WindyTypes }>()
);
const SetActiveOverlayMapLayer = createAction(
  MapLayerActions.SET_OVERLAY_ACTIVE_LAYER,
  props<{ layerId: string }>()
);

const EmitMapZoomValueAction = createAction(
  MapLayerActions.EMIT_MAP_ZOOM_VALUE,
  props<{ zoom: number }>()
);

const MapClickAction = createAction(
  MapLayerActions.MAP_CLICK_ACTION,
  props<{ latLng: L.LatLng }>()
);
const MarkerClickAction = createAction(
  MapLayerActions.MARKER_CLICK_ACTION,
  props<{ id: string }>()
);
const MarkerDragAction = createAction(
  MapLayerActions.MARKER_DRAG_ACTION,
  props<{ id: string; latLng: L.LatLng }>()
);

const LineClickAction = createAction(
  MapLayerActions.LINE_CLICK_ACTION,
  props<{ latLng: L.LatLng; meta: LineMeta }>()
);

export {
  SetMapLayersConfigAction,
  SetActiveBaseMapLayer,
  SetActiveOverlayMapLayer,
  EmitMapZoomValueAction,
  MapClickAction,
  MarkerClickAction,
  MarkerDragAction,
  LineClickAction,
  ToggleWindyLayerAction,
};
