import { Injectable } from '@angular/core';
import { EnvVarsService } from 'src/app/env-vars.service';
import { CommonApiService } from '@core/api/common.api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NaviqoreClusteringDataApiService {
  private API_URL = this.environment.getEnvKey('EXPORTS_API_URL');
  private namespace = 'clustering';
  private url = `${this.API_URL}/${this.namespace}`;
  constructor(
    private common: CommonApiService,
    private environment: EnvVarsService
  ) {}

  public getClusteredPerVariable(
    vesselId: string,
    obj: ClusteringRequest
  ): Observable<DataResponse> {
    return this.common.post(`${this.url}/${vesselId}`, obj);
  }
}

type ClusteringValue = {
  label: string;
  count: number;
};
type ClusteringRanges = ClusteringValue[];

export type ClusteringDataResponse = ClusteringRanges[];

type NoDataFoundResponse = {
  detail: string;
};

export type DataResponse = ClusteringDataResponse & NoDataFoundResponse;

export type ClusteringRequest = {
  start: string;
  end: string;
  variables: { tagId: number }[];
  filters: [];
};
