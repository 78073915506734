import { inject, Injectable } from '@angular/core';
import {
  ClusteringRequest,
  NaviqoreClusteringDataApiService,
} from '@core/api/naviqore-clustering-data-api.service';
import {
  NaviqoreDataApiService,
  ExportGraphRequest,
} from '@core/api/naviqore-data-api.service';
import { TimeZoneFormat } from '@core/constants/time.defaults';
import padWithZeros from '@core/utils/padWithZeros';
import { SetVariablesPayloadType } from '@dashboard-store';
import { ExtraStateIds } from '@dashboard/models';
import { DataSeriesVariable } from '@dashboard/services/data-to-api.service';
import { forkJoin, map, of } from 'rxjs';

type QueryApiRequests = {
  queryDataPayload: ExportGraphRequest;
  clusteringPayload: ClusteringRequest;
};

type QueryApiVars = {
  queryDataVars: DataSeriesVariable[];
  clusteringVars: DataSeriesVariable[];
  timezoneHours: number;
};

@Injectable({
  providedIn: 'root',
})
export class ApiEffectsHelperService {
  private _queryDataApi = inject(NaviqoreDataApiService);
  private _clusteringApi = inject(NaviqoreClusteringDataApiService);

  public ApiDataRequestsToState(
    selectedImo: string,
    toDate: string,
    { queryDataPayload, clusteringPayload }: QueryApiRequests,
    { queryDataVars, clusteringVars, timezoneHours }: QueryApiVars
  ) {
    const QueryDataApi = this.QueryDataRequestToState(
      selectedImo,
      toDate,
      queryDataPayload,
      queryDataVars,
      timezoneHours
    );
    const ClusteringDataApi = this.ClusteringRequestToState(
      selectedImo,
      toDate,
      clusteringPayload,
      clusteringVars
    );

    return forkJoin([QueryDataApi, ClusteringDataApi]);
  }

  private QueryDataRequestToState(
    selectedImo: string,
    toDate: string,
    payload: QueryApiRequests['queryDataPayload'],
    vars: QueryApiVars['queryDataVars'],
    timezoneHours: number
  ) {
    return this._queryDataApi
      .getExportGraphApiData(selectedImo, payload, timezoneHours)
      .pipe(
        map((response) => {
          const VesselVariableData: SetVariablesPayloadType[] = [];
          if ('data' in response) {
            const timestamps = response.timestamps;
            const d = response.data.map((serie) => {
              return serie.tagData.map((serieData, index) => ({
                x: timestamps[index],
                y: serieData as number,
              }));
            });

            d.map((serie, index) => {
              const dat: SetVariablesPayloadType = [
                padWithZeros(selectedImo, 8),
                vars[index].stateId as ExtraStateIds,
                {
                  formattedValue: null,
                  timestamp: TimeZoneFormat(toDate).valueOf(),
                  variableId: 'removethis',
                  notInSync: false,
                  values: serie,
                },
              ];

              VesselVariableData.push(dat);
            });
          }
          return VesselVariableData;
        })
      );
  }
  private ClusteringRequestToState(
    selectedImo: string,
    toDate: string,
    payload: QueryApiRequests['clusteringPayload'],
    vars: QueryApiVars['clusteringVars']
  ) {
    if (vars.length === 0) {
      return of([]);
    }
    return this._clusteringApi
      .getClusteredPerVariable(selectedImo, payload)
      .pipe(
        map((response) => {
          const VesselVariableData: SetVariablesPayloadType[] = [];
          const _DATA = { data: response };
          if ('data' in _DATA) {
            console.log({ _DATA });
            const d = _DATA.data.map((serie) => {
              return serie.map(({ label, count }) => {
                if (label === 'None') return null;
                return {
                  x: label,
                  y: count as number,
                };
              });
            });
            d.filter((d) => !!d).map((serie, index) => {
              const dat: SetVariablesPayloadType = [
                padWithZeros(selectedImo, 8),
                vars[index].stateId as ExtraStateIds,
                {
                  formattedValue: null,
                  timestamp: TimeZoneFormat(toDate).valueOf(),
                  variableId: 'removethis',
                  notInSync: false,
                  values: serie,
                },
              ];
              VesselVariableData.push(dat);
            });
          }
          return VesselVariableData;
        })
      );
  }
}
