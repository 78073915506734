import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  ErrorHandler,
  NgModule,
  Provider,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './routes/app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { GlobalErrorHandler } from '@core/services/interceptors/global-error-handler.interceptor';
import { TokenInterceptorService } from '@core/services/interceptors/token-interceptor';
import * as Sentry from '@sentry/angular-ivy';
import { StoreModule } from '@ngrx/store';
import { Router } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import dashboardBlockedActions from '@dashboard/dashboard-store/dashboard-blocked-actions';
import { blockedActions } from '@course-planning-store';
import { EnvVarsService } from './env-vars.service';
import { ConfigSharedComponentsModule } from '@shared/components/config-shared-components.module';

registerLocaleData(en);

// const appInitializerFn = (appConfig: AppConfigService) => {
//   return () => {
//     return appConfig.loadAppConfig();
//   };
// };
const SentryProviders: Array<Provider | EnvironmentProviders> = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];

const providers: Array<Provider | EnvironmentProviders> = [
  // {
  //   provide: APP_INITIALIZER,
  //   useFactory: appInitializerFn,
  //   multi: true,
  //   deps: [AppConfigService],
  // },
  { provide: NZ_I18N, useValue: en_US },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: GlobalErrorHandler,
    multi: true,
  },
];

const environment = new EnvVarsService();

if (
  environment.getEnvKey('production') &&
  environment.getEnvKey('IS_COMMERCIAL')
) {
  providers.push(...SentryProviders);
}

@NgModule({
  declarations: [AppComponent],
  providers,
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    ConfigSharedComponentsModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: true, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      connectInZone: true,
      actionsBlocklist: [...dashboardBlockedActions, ...blockedActions],
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
