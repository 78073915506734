import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@core/services/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('../modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import(
        '../shared/modules/dynamic-dashboards/dynamic-dashboards.module'
      ).then((m) => m.DynamicDashboardsModule),

    data: {
      title: 'Dashboard',
    },
  },
  {
    path: 'tools',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('../modules/tools-page/tools-page.module').then(
        (m) => m.ToolsPageModule
      ),
  },
  {
    path: 'dt-backbone',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import(
        '../shared/modules/dynamic-dashboards/dynamic-dashboards.module'
      ).then((m) => m.DynamicDashboardsModule),

    data: {
      title: 'Dashboard',
    },
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
//
