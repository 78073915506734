import { Injectable, inject } from '@angular/core';
import { WeatherApiService } from '@core/api/weather-api.service';
import { NotificationsService } from '@core/services/helpers/notifications.service';
import {
  ActiveLayersMapSelector,
  EmitMapZoomValueAction,
  FocusMapMarkerAction,
  MapClickAction,
  MapClicksSelector,
  RightBarViewSelector,
  SelectedVesselSelector,
  SetActiveBaseMapLayer,
  SetActiveOverlayMapLayer,
  SetMapLayersConfigAction,
  SetValueDmsMapClicksAction,
} from '@dashboard-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MapApiService } from '@widgets/map-widget/services/map-api.service';
import { MapLayersService } from '@widgets/map-widget/services/map-layers.service';
import { WeatherDataService } from '@widgets/map-widget/services/weather-data.service';
import { EMPTY, debounceTime, map, of, switchMap, withLatestFrom } from 'rxjs';

@Injectable()
export class MapEffects {
  private _weatherApi = inject(WeatherApiService);
  private _notif = inject(NotificationsService);
  private _weatherData = inject(WeatherDataService);
  private actions$ = inject(Actions);
  private _store = inject(Store);
  private _mapApi = inject(MapApiService);
  private _mapLayers = inject(MapLayersService);

  SetMapLayersEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SetMapLayersConfigAction),
        map(({ baseMaplayers, overlayLayers }) => {
          this._mapLayers.setMapLayers = {
            baseLayers: baseMaplayers,
            overlay: overlayLayers,
          };
          return;
        })
      );
    },
    { dispatch: false }
  );

  FocusMarkerEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FocusMapMarkerAction),
        withLatestFrom(this._store.select(SelectedVesselSelector)),
        map(([{ vesselId }, activeVesselId]) => {
          this._mapApi.FocusMapMarker(vesselId || activeVesselId);
        })
      );
    },
    { dispatch: false }
  );

  SetActiveBaseMapLayer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          SetActiveBaseMapLayer,
          SetActiveOverlayMapLayer,
          EmitMapZoomValueAction
        ),
        debounceTime(500),
        withLatestFrom(this._store.select(ActiveLayersMapSelector)),
        map(([, activeLayers]) => {
          this._mapLayers.ReInstantiateLayers(activeLayers);
        })
      );
    },
    { dispatch: false }
  );

  zoomEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EmitMapZoomValueAction),
        debounceTime(100),
        map((zoomAction) => {
          this._mapApi.setZoomLvl(zoomAction.zoom);
          return;
        })
      );
    },
    { dispatch: false }
  );

  setDmsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MapClickAction),
      withLatestFrom(
        this._store.select(RightBarViewSelector),
        this._store.select(MapClicksSelector)
      ),
      switchMap(([{ latLng }, rightbar, dmsState]) => {
        const { firstClick, secondClick, enabled } = dmsState;
        if (!enabled) return EMPTY;
        if (rightbar === 'routeOptimazation') return EMPTY;

        let newDmsState = { ...dmsState };
        const BothClicksHaveValues = firstClick && secondClick;
        const ClicksHasNoValues = !firstClick && !secondClick;
        if (ClicksHasNoValues) {
          newDmsState = {
            enabled: true,
            firstClick: latLng,
            secondClick: null,
          };
        } else if (BothClicksHaveValues) {
          newDmsState = {
            enabled: true,
            firstClick: secondClick,
            secondClick: latLng,
          };
        } else {
          newDmsState = {
            enabled: true,
            firstClick: firstClick || latLng,
            secondClick: firstClick ? latLng : null,
          };
        }

        return of(SetValueDmsMapClicksAction(newDmsState));
      })
    );
  });
}
