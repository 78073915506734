import {
  ExtendButton,
  ExternalRenderLayerOptions,
} from '@dashboard/components/extend-more-buttons/extend-more-buttons.component';
import { StoreDashboardState } from '@dashboard/dashboard-store/dashboard.state';
import {
  SelectedBaseMapLayers,
  SelectedOverlayMapLayers,
  ZoomSelector,
} from '@dashboard/dashboard-store/selectors/ui-state.selector';
import {
  MemoizedSelector,
  createFeatureSelector,
  createSelectorFactory,
  defaultMemoize,
} from '@ngrx/store';
import { isEqual } from 'lodash';

const DeepSelector = createSelectorFactory((projectionFn) =>
  defaultMemoize(
    projectionFn,
    arraysAndObjectsComparison,
    arraysAndObjectsComparison
  )
);
function arraysAndObjectsComparison(a: any, b: any): boolean {
  const res = isEqual(a, b);
  return res;
}
const UiState = createFeatureSelector<StoreDashboardState>('dashboard');

const DashboardBaseButtonsState = DeepSelector(
  UiState,
  SelectedBaseMapLayers,
  ZoomSelector,
  (
    state: StoreDashboardState,
    selectedBaseLayer: StoreDashboardState['uiState']['map']['selectedBaseLayer'],
    zoom: number
  ) => {
    const baseBtns = state.uiState.map.baseLayers;
    return {
      label: baseBtns.label,
      icon: baseBtns.icon,
      ...baseBtns,
      layers: baseBtns.layers.map((layer, index) => {
        const ExtraRenderProps = layer.layerGroup.reduce((prev, layer) => {
          if (layer.startZoom <= zoom && layer.endZoom > zoom) {
            return { ...prev, ...layer };
          }
          return prev;
        }, {} as ExternalRenderLayerOptions);
        const obj: ExtendButton = {
          id: index.toString(),
          tooltip: layer.description,
          icon: layer.img,
          selected: selectedBaseLayer.includes(index.toString()),
          ...ExtraRenderProps,
          ...layer,
        };
        return obj;
      }),
    };
  }
) as MemoizedSelector<
  StoreDashboardState,
  { label: string; icon: string; layers: ExtendButton[] }
>;

const DashboardOverlayButtonsState = DeepSelector(
  UiState,
  SelectedOverlayMapLayers,
  ZoomSelector,
  (
    state: StoreDashboardState,
    selectedOverlayLayer: StoreDashboardState['uiState']['map']['selectedOverlayLayers'],
    zoom: number
  ) => {
    const overlayBtn = state.uiState.map.layers;
    return {
      label: overlayBtn.label,
      icon: overlayBtn.icon,
      layers: overlayBtn.layers.map((layer, index) => {
        const ExtraRenderProps = layer.layerGroup.reduce((prev, layer) => {
          if (layer.startZoom <= zoom && layer.endZoom > zoom) {
            return { ...prev, ...layer };
          }
          return prev;
        }, {} as ExternalRenderLayerOptions);

        const isAvailable = layer.layerGroup.reduce((prev, layer) => {
          if (layer.startZoom <= zoom && layer.endZoom > zoom) {
            return prev || true;
          }
          return prev;
        }, false as boolean);

        const obj: ExtendButton = {
          id: index.toString(),
          tooltip: layer.description,
          icon: layer.img,
          selected: selectedOverlayLayer.includes(index.toString()),
          disabled: !isAvailable,
          ...ExtraRenderProps,
        };

        return obj;
      }),
    };
  }
) as MemoizedSelector<
  StoreDashboardState,
  { label: string; icon: string; layers: ExtendButton[] }
>;

export { DashboardOverlayButtonsState, DashboardBaseButtonsState };
