import { Injectable } from '@angular/core';
import { CommonApiService } from '@core/api/common.api.service';
import { catchError, of } from 'rxjs';
import { EnvVarsService } from 'src/app/env-vars.service';

export type VesselStateResponse = {
  imo: string;
  states: VesselState[];
};
type VesselState = {
  stateTimestamp: number;
  tags: { [signalId: string]: VesselTagResponseValue };
};
type VesselTagResponseValue = {
  timestamp: number;
  value: string | number | boolean;
  formattedValue: string;
  notInSync: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class StateApiService {
  private API_URL = this.environment.getEnvKey('EXPORTS_API_URL');
  private namespace = 'state';
  private url = `${this.API_URL}/${this.namespace}`;
  // private url = `http://168.119.15.247:3013/${this.namespace}`;

  constructor(
    private common: CommonApiService,
    private environment: EnvVarsService
  ) {}

  public getAllVesselsState(date?: string) {
    const _date = !date ? 'latest' : date;
    return this.common
      .get<VesselStateResponse[]>(`${this.url}/all/${_date ?? 'latest'}`)
      .pipe(
        catchError((error) => {
          console.error('Error fetching vessel state', error);
          return of(null);
        })
      );
  }

  public getVesselState(imo: string, date?: string) {
    return this.common
      .get<VesselStateResponse>(`${this.url}/vessel/${imo}/${date ?? 'latest'}`)
      .pipe(
        catchError((error) => {
          console.error('Error fetching vessel state', error);
          return of(null);
        })
      );
  }
}
