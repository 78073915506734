import { LatLng } from 'leaflet';

export function DistanceCalculator(latlng1: LatLng, latlng2: LatLng) {
  const R = 3440.065; // Earth's radius in nautical miles
  const lat1 = (latlng1.lat * Math.PI) / 180;
  const lat2 = (latlng2.lat * Math.PI) / 180;
  const dLat = lat2 - lat1;
  const dLon = ((latlng2.lng - latlng1.lng) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}
