export class GlobalSettings {
  _id: string;
  name: string;
  versions: IVersionHistoryItem[];

  constructor(data) {
    this._id = data._id || null;
    this.name = data.name || '-';
    this.versions = data.versions || [];
  }
}

export interface IVersionHistoryItem {
  title: string;
  versionNumber: string;
  date: string;
  sections: IVersionHistorySection[];
}
interface IVersionHistorySection {
  title: string;
  children: string[];
}
