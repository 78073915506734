import {
  MapClickAction,
  MarkerClickAction,
  MarkerDragAction,
} from '@dashboard/dashboard-store/actions';

export default [
  MapClickAction.type,
  MarkerClickAction.type,
  MarkerDragAction.type,
];
