import { Injectable, inject } from '@angular/core';
import { GenerateRandomString } from '@core/services/helpers/uuid';
import {
  AddWaypointAction,
  FromCurrSelector,
  GetRouteOptApiAction,
  RemoveWaypointAction,
  SetMilesAction,
  SetResultsAction,
  SetWaypointsAction,
  ToggleFromCurrLocAction,
  UpdateWaypoint,
  Waypoint,
  WaypointEditingStateSelector,
  clearWaypointsAction,
  mapWaypointsSelector,
} from '@course-planning-store';
import {
  MapClickAction,
  MarkerClickAction,
  RightBarViewSelector,
  SelectedVesselImo,
  SelectedVesselLatLng,
  SelectedVesselSelector,
  SetRightBar,
  SetSelectedVesselAction,
} from '@dashboard/dashboard-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LatLng } from 'leaflet';
import { EMPTY, catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { RouteOptimizationApiService } from '../../route-optim-api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class CourseEffects {
  private _notify = inject(NzNotificationService);

  AddNewWaypoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MapClickAction),
      withLatestFrom(this._store.select(WaypointEditingStateSelector)),
      mergeMap(([{ latLng }, activeEditing]) => {
        if (!activeEditing) return EMPTY;

        return of(
          AddWaypointAction({
            waypoint: { ...latLng, id: GenerateRandomString() },
          })
        );
      })
    );
  });

  RemoveWaypoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarkerClickAction),
      withLatestFrom(this._store.select(WaypointEditingStateSelector)),
      mergeMap(([{ id }, activeEditing]) => {
        if (!activeEditing) return EMPTY;

        return of(
          RemoveWaypointAction({
            id,
          })
        );
      })
    );
  });

  FetchResults$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetRouteOptApiAction),
      withLatestFrom(this._store.select(SelectedVesselImo)),
      mergeMap(([{ payload }, vesselId]) => {
        return this.courseOptServ.RouteOptimization(vesselId, payload).pipe(
          map((results) => {
            // Dispatch a success action with the data
            return SetResultsAction({
              results,
            });
          }),
          catchError(() => {
            this._notify.error('Error', 'Failed to fetch results');
            // Dispatch a failure action with the error
            return EMPTY;
          })
        );
      })
    );
  });

  // DragWaypoint$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(MarkerDragAction),
  //     map(({ id, latLng }) => {
  //       return UpdateWaypoint({
  //         id,
  //         latLng,
  //       });
  //     })
  //   );
  // });

  clearWaypoints$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clearWaypointsAction),
      withLatestFrom(
        this._store.select(mapWaypointsSelector),
        this._store.select(FromCurrSelector)
      ),
      mergeMap(([, waypoints, isClicked]) => {
        const hasInitPoint =
          waypoints.length > 0 ? waypoints[0].id === 'init' : false;

        if (isClicked && hasInitPoint) {
          return of(
            SetWaypointsAction({
              waypoints: [...waypoints.filter((x) => x.id === 'init')],
            }),
            SetMilesAction({ miles: 0 })
          );
        } else {
          return of(
            SetWaypointsAction({ waypoints: [] }),
            SetMilesAction({ miles: 0 })
          );
        }
      })
    );
  });

  startFromVessel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetSelectedVesselAction, ToggleFromCurrLocAction, SetRightBar),
      withLatestFrom(
        this._store.select(SelectedVesselSelector),
        this._store.select(mapWaypointsSelector),
        this._store.select(SelectedVesselLatLng),
        this._store.select(FromCurrSelector),
        this._store.select(RightBarViewSelector)
      ),
      mergeMap(([, vesselId, waypoints, vesselLatLng, isClicked, rightBar]) => {
        if (rightBar === 'routeOptimazation') {
          const hasInitPoint =
            waypoints.length > 0 ? waypoints[0].id === 'init' : false;

          if (vesselId === '' || vesselId === null) {
            if (isClicked) {
              if (hasInitPoint) {
                return of(
                  RemoveWaypointAction({
                    id: 'init',
                  })
                );
              } else {
                return EMPTY;
              }
            } else {
              if (hasInitPoint) {
                return of(
                  RemoveWaypointAction({
                    id: 'init',
                  })
                );
              } else {
                return EMPTY;
              }
            }
          } else {
            if (isClicked) {
              if (hasInitPoint) {
                return of(
                  UpdateWaypoint({
                    id: 'init',
                    lat: vesselLatLng?.lat ? Number(vesselLatLng.lat) : null,
                    lng: vesselLatLng?.lng ? Number(vesselLatLng.lng) : null,
                  })
                );
              } else {
                console.log(vesselLatLng?.lat);
                console.log(vesselLatLng?.lng);

                return of(
                  AddWaypointAction({
                    waypoint: {
                      id: 'init',
                      lat: vesselLatLng?.lat ? Number(vesselLatLng.lat) : null,
                      lng: vesselLatLng?.lng ? Number(vesselLatLng.lng) : null,
                    },
                    onStart: true,
                  })
                );
              }
            } else {
              if (hasInitPoint) {
                return of(
                  RemoveWaypointAction({
                    id: 'init',
                  })
                );
              } else {
                return EMPTY;
              }
            }
          }
        } else {
          return EMPTY;
        }
      })
    );
  });

  // LineClick$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(LineClickAction),
  //     withLatestFrom(
  //       this._store.select(mapWaypointsSelector),
  //       this._store.select(WaypointEditingStateSelector)
  //     ),
  //     map(([{ meta, latLng }, waypoints, activeEditing]) => {
  //       if (!activeEditing) return;
  //       const ids = meta.idsBetweenPoints;
  //       const newWaypoints = this.AddNewWaypointToNewIndex(
  //         ids,
  //         waypoints,
  //         latLng
  //       );
  //       return SetWaypointsAction({ waypoints: newWaypoints });
  //     })
  //   );
  // });

  constructor(
    private actions$: Actions,
    private _store: Store,
    public courseOptServ: RouteOptimizationApiService
  ) {}

  private AddNewWaypointToNewIndex(
    ids: [string, string],
    waypoints: Waypoint[],
    latLng: LatLng
  ): Waypoint[] {
    const indexRef = waypoints.findIndex((w) => w.id === ids[0]);
    if (indexRef === -1) return waypoints;
    const newWaypoint = { ...latLng, id: GenerateRandomString() };

    const newWaypoints = [...waypoints];
    newWaypoints.splice(indexRef + 1, 0, newWaypoint);
    return newWaypoints;
  }
}
