export type ExtraStateIds = keyof typeof ExtraStateIdsKeys;

export const ExtraStateIdsKeys = {
  latest_timestamp: 'latest_timestamp',
  ingestion_error: 'ingestion_error',
  chart_ME_Power: 'chart_ME_Power',
  chart_ME_Power_Ref_HeavyProp: 'chart_ME_Power_Ref_HeavyProp',
  chart_ME_Power_Ref_LightProp: 'chart_ME_Power_Ref_LightProp',
  chart_speed_through_water: 'chart_speed_through_water',
  chart_speed_over_ground: 'chart_speed_over_ground',
  chart_WindSpeedAbsBF: 'chart_WindSpeedAbsBF',
  chart_ME_RPM: 'chart_ME_RPM',
  chart_Current: 'chart_Current',
  chart_Slip: 'chart_Slip',
  chart_ME_FOC_TPD: 'chart_ME_FOC_TPD',
  chart_DraftAft: 'chart_DraftAft',
  chart_DraftFor: 'chart_DraftFor',
  chart_DraftMidP: 'chart_DraftMidP',
  chart_DraftMidS: 'chart_DraftMidS',
  downsample_wind: 'chart_power_123',
  downsample_engine_speed: 'downsample_engine_speed',
  downsample_engine_power: 'downsample_engine_power',
  downsample_vessel_speed: 'downsample_vessel_speed',
  trace: 'trace',
  bar_windspeed_bf: 'bar_windspeed_bf',
  bar_engine_speed: 'bar_engine_speed',
  bar_sog: 'bar_sog',
  pie_steaming_time: 'pie_steaming_time',
} as const;
