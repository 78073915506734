import { createActionGroup, emptyProps } from '@ngrx/store';

const TraceApiActions = createActionGroup({
  source: 'TRACE_API',
  events: {
    call: emptyProps(),
    success: emptyProps(),
    failure: emptyProps(),
  },
});

export { TraceApiActions };
