import moment, { Moment } from 'moment';

export const defaultMultiLanguage = { EN: 'N/A', GR: 'N/A' };
export const DATE_FORMAT_UTC = 'YYYY-MM-DD[T]HH:mm:ss.SSZ';
export const DATE_FORMAT_CHART = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_INPUT = 'YYYY-MM-dd HH:mm';
// export const DATE_FORMAT_NO_TIME = 'YYYY-MM-DD';
export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DISPLAY_DATE_FORM = 'YYYY-MM-DD HH:mm:ss';
export const REQUEST_DATE_FORM = 'YYYY-MM-DD HH:mm:ss';
export const ChartPointFormat = 'YYYY-MM-DD HH:mm:ss';
export const ChartXAxisFormat = 'DD-MM HH:mm';

export const DateNow = () => {
  return moment().format(DATE_FORMAT);
};

export const FormatDateWithTime = (dateInMs: number = undefined) =>
  moment(dateInMs).startOf('m').format(DISPLAY_DATE_FORM);

export const GetUTCFormat = (
  date: string | Moment = moment().toString()
): string => {
  return moment(date).format(DATE_FORMAT_UTC);
};
export const GetReportingTimeFormat = (date: Date) => {
  const m = moment(date);
  return GetUTCFormat(m);
};

export const TimeZoneFormat = (dateInUTC: string = moment().toString()) => {
  return moment.utc(dateInUTC).local();
};

export const QueryDataDates = (date: string = undefined) => {
  return moment(date).utc().format(DATE_FORMAT).toString();
};
export const VesselStageApiFormating = (date: string = undefined) => {
  return moment.parseZone(date).utc().format(DATE_FORMAT);
};
export const ApiTimeUTC = (date: string) => {
  return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
};
export const WeatherDateFormating = (date: string = undefined) => {
  return moment(date).utc().format('YYYYMMDDHH');
};

export function TimestampIsInCurrentSecond(date: string) {
  return moment(date).isBetween(
    moment().startOf('minute'),
    moment().endOf('minute')
  );
}

export function applyTimezone(
  date: string | Moment = moment(),
  targetTimezone: number = null
) {
  // Step 1: Parse the date in its original timezone
  const parsedDate = typeof date == 'string' ? moment.parseZone(date) : date;

  // Adjust the date to the specified target timezone offset
  const timezone = targetTimezone || parsedDate.format('Z');

  const adjustedDate = parsedDate.clone().utcOffset(timezone);

  return {
    getDate() {
      return adjustedDate.toDate();
    },
    getMomentDate() {
      return adjustedDate;
    },
    GetUTCFormat() {
      return GetUTCFormat(adjustedDate);
    },
  };
}

export function DateToItsTimezone(
  date: Date | string | Moment,
  timezoneHours: number
) {
  const timezoneMinutes = timezoneHours * 60;
  return moment(date)
    .utcOffset(timezoneMinutes)
    .format('YYYY-MM-DDTHH:mm:ss.SSZZ');
}

export function ApplyTimezoneToLocalDate(
  date: Date | string | Moment,
  timezoneHours: number
) {
  const timezoneMinutes = timezoneHours * 60;

  const localTimezoneToHours = moment().utcOffset() / 60;
  /**
   * 13:00 UTC-1 -> 16:00 UTC+2 ( neeed to sabtract 3 hours local - tz = 2 - (-1) = 3)
   * 13:00 UTC+4 -> 10:00 UTC+2 ( neeed to add 2 hours local - tz = 2 - 4 = -2)
   */

  const diff = (localTimezoneToHours * 60 - timezoneMinutes) * -1;

  const _date = moment(date).add(diff, 'm');
  return {
    moment() {
      return _date;
    },
    toDate() {
      return _date.toDate();
    },
  };
}
export function LocalTZtoTimezone(date: string, timezoneStr: string) {
  const updatedDateString = date.replace(/([+-]\d{2}:\d{2}|Z)$/, timezoneStr);

  return updatedDateString;
}
export function adjustTimeToTargetTimezone(
  date: Date,
  prevTimezone: number | null,
  currentTimezoneHours: number
) {
  const localTimezoneToHours = moment().utcOffset() / 60;

  const prevTimezoneOffsetInHours = prevTimezone ?? localTimezoneToHours;
  const CurrentTimezoneOffsetInHours =
    currentTimezoneHours ?? localTimezoneToHours;

  //diff can be negative or prositive
  const diff = CurrentTimezoneOffsetInHours - prevTimezoneOffsetInHours;

  const newDate = moment(date).add(diff, 'hours');

  return {
    getMomentDate() {
      return newDate;
    },
    getDate() {
      return newDate.toDate();
    },
    GetUTCFormat() {
      return GetUTCFormat(newDate);
    },
    toString() {
      return moment(newDate).format('MMM ddd HH:mm');
    },
  };
}
