import { inject, Injectable } from '@angular/core';
import { NotificationsService } from '@core/services/helpers/notifications.service';
import { WindyTypes } from '@dashboard/dashboard-store';
import L from 'leaflet';
import { Layer, Map } from 'leaflet';
import moment from 'moment';
import { Subscription, timer } from 'rxjs';

const INITIAL_WEATHER_DATA: { wind: any; waves: any } = {
  wind: null,
  waves: null,
};
@Injectable({
  providedIn: 'root',
})
export class WeatherDataService {
  private _notifictions = inject(NotificationsService);
  private _map: Map;
  private $WeatherDataTimer: Subscription;
  private Layers: { windyLayer: Layer } = { windyLayer: null };

  private MapWindyData = { ...INITIAL_WEATHER_DATA };

  public set map(map: Map) {
    this._map = map;
  }

  public setWindData(data: any) {
    console.debug('Set wind data');
    this.MapWindyData.wind = data;
  }
  public setWavesData(data: any) {
    console.debug('Set waves data');
    this.MapWindyData.waves = data;
  }

  public InitWeatherDataTimer(cb: () => void) {
    this.StopWeatherDataTimer();
    console.debug('Weather Timer Started');

    const rangeToEndOfHour = moment().endOf('hour').diff(moment());
    const AnHourMilliseconds = 3_600_000;

    this.$WeatherDataTimer = timer(
      rangeToEndOfHour,
      AnHourMilliseconds
    ).subscribe(() => {
      cb();
    });
  }
  public StopWeatherDataTimer() {
    if (!this.$WeatherDataTimer) return;
    console.debug('Weather Timer Stopped');
    this.$WeatherDataTimer.unsubscribe();
    this.$WeatherDataTimer = null;
  }

  public showWindyLayer(windyType: WindyTypes) {
    let data: any;
    console.log('SHOWING LAYER', { windyType, data: this.MapWindyData });
    this.hideWindyLayer();
    if (windyType === 'waves') {
      data = this.MapWindyData.waves;
    } else if (windyType === 'wind') {
      data = this.MapWindyData.wind;
    } else {
      this.hideWindyLayer();
    }
    if (!data) {
      console.error(`NO DATA FOR LAYER: ${windyType}`);
      return;
    }

    const velocityLayer2 = (L as any).velocityLayer({
      displayValues: true,
      displayOptions: {
        velocityType: `${windyType}`,
        position: 'bottomleft',
        emptyString: `No ${windyType} data`,
        showCardinal: true,
      },
      data,
      minVelocity: 0,
      maxVelocity: 25,
      velocityScale: 0.007,
      colorScale: ['green', 'white', 'red'], // ['dark','green','red']
      opacity: 1,
      particleMultiplier: 1 / 300,
      lineWidth: 0.9,
    }) as L.Layer;

    setTimeout(() => {
      this._map.addLayer(velocityLayer2);
      console.debug(`WINDY LAYER ADDED: ${windyType}`);

      this.Layers.windyLayer = velocityLayer2;
    }, 100);
  }

  public hideWindyLayer() {
    if (this.Layers.windyLayer) {
      this._map.removeLayer(this.Layers.windyLayer);
      this.Layers.windyLayer = null;
    }
  }

  public clearWeatherData() {
    console.debug('Weather Data Cleared');
    this.hideWindyLayer();
    this.MapWindyData = { ...INITIAL_WEATHER_DATA };
    console.log({ MapWindyData: this.MapWindyData });
  }
}
