import { createActionGroup, emptyProps } from '@ngrx/store';

const FleetApiActions = createActionGroup({
  source: 'FLEET_API',
  events: {
    call: emptyProps(),
    success: emptyProps(),
    failure: emptyProps(),
  },
});

export { FleetApiActions };
