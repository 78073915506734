import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AppInitializeAction,
  FleetApiActions,
  SetSystemDatesAction,
  SetVariablesDataAction,
} from '../actions';
import {
  catchError,
  EMPTY,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { StoreInitService } from '@dashboard/dashboard-library/helpers/store-init.service';
import { TimelineService } from '@dashboard/components/map-timeline/timeline.service';
import { ToDateSelector } from '../selectors';

@Injectable()
export class FleetEffects {
  constructor(
    private actions$: Actions,
    private _store: Store,
    private _storeInitHelper: StoreInitService,
    private _timeline: TimelineService
  ) {}

  trigerLoadFleetData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppInitializeAction, SetSystemDatesAction),

      mergeMap((action) => {
        const isDateAction = action.type === SetSystemDatesAction.type;

        if (isDateAction && !action.toDate) return EMPTY;

        return of(FleetApiActions.call());
      })
    )
  );

  //Every time someone changes the date we need to update the fleet data.
  loadFleetData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetApiActions.call),
      withLatestFrom(this._store.select(ToDateSelector)),
      switchMap(([, activeDate]) =>
        this._storeInitHelper.getInitialVesselsData(
          this._timeline.isRealtime$.getValue() ? undefined : activeDate
        )
      ),
      switchMap((variableMeta) =>
        of(SetVariablesDataAction({ variableMeta }), FleetApiActions.success())
      ),
      catchError(() => of(FleetApiActions.failure()))
    )
  );
}
