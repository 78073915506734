import { inject, Injectable } from '@angular/core';
import padWithZeros from '@core/utils/padWithZeros';
import {
  SetVariablesDataAction,
  SetVariablesPayloadType,
} from '@dashboard/dashboard-store';
import { IngestionErrorEvaluationData } from '@dashboard/dashboard-store/selectors/system-errors.selector';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { firstValueFrom, Subscription, timer } from 'rxjs';
import { EnvVarsService } from '../../../../env-vars.service';

@Injectable()
export class SystemDataErrorsService {
  private _store = inject(Store);
  private environment = inject(EnvVarsService);

  private VESSEL_OFFLINE_TIMEOUT_HOURS =
    this.environment.getEnvKey('VESSEL_OFFLINE_TIMEOUT_HOURS') || 2;
  private $IngestionTimer: Subscription;
  constructor() {}

  public InitIngestionErrorChecker() {
    console.debug('Ingestion Error Timer Started');

    // const rangeToEndOfSeocondMin =
    //   moment().endOf('minute').diff(moment())
    const TenSecsInMs = 10_000;
    const AnHourMs = 3_600_000;

    this.$IngestionTimer = timer(TenSecsInMs, AnHourMs).subscribe(() => {
      this.IngestionErrorChecker();
    });
  }

  public stopIngestionErrorChecker() {
    this.$IngestionTimer.unsubscribe();
  }

  public async IngestionErrorChecker() {
    const vesselsData = await firstValueFrom(
      this._store.select(IngestionErrorEvaluationData)
    );
    const variableMeta = vesselsData
      .map((d) => ({
        hasError: this.IgestionHasErrorRule(
          d.systemHealth,
          d.latest_timestamp * 1000
        ),
        imo: d.imo,
      }))
      .map(({ imo, hasError }) => {
        const payload: SetVariablesPayloadType = [
          padWithZeros(imo, 8),
          'ingestion_error',
          {
            notInSync: false,
            variableId: 'ingestion_error',
            timestamp: moment().valueOf() / 1000,
            formattedValue: `${hasError}`,
          },
        ];
        return payload;
      });

    this._store.dispatch(SetVariablesDataAction({ variableMeta }));
  }

  private IgestionHasErrorRule(
    systemHealth: string,
    state_timestamp_ms: number
  ) {
    const range = moment().diff(moment(state_timestamp_ms), 'hours', true);
    const HoursArePassed = Math.abs(range) > this.VESSEL_OFFLINE_TIMEOUT_HOURS;

    const statusIsOffline = systemHealth === 'offline';

    return HoursArePassed || statusIsOffline;
  }
}
