import { Injectable, Input, inject } from '@angular/core';
import { DIRECTIONAL_SVG } from '../directional.svg';
import {
  MapLine,
  MarkerStateType,
  MapMarker,
  MapStateItem,
  MapState,
  MapStateProps,
} from '../map.models';
import { MapUtilsService } from './map-utils.service';
import 'leaflet';
import 'leaflet-rotatedmarker';
import './windy.js';

import {
  PosColor,
  Position,
} from '../../../shared/modules/dynamic-dashboards/dashboard-library/types/map.types';
import { BehaviorSubject, take } from 'rxjs';
import {
  FeatureGroup,
  LatLng,
  Map,
  Marker,
  featureGroup,
  layerGroup,
  map as lMap,
  LayerGroup,
  Layer,
  LatLngBoundsExpression,
} from 'leaflet';
import L from 'leaflet';
import { MapLayersService } from '@widgets/map-widget/services/map-layers.service';
import initMapZoom from './map.config';
import { Store, select } from '@ngrx/store';
import { ZoomSelector } from '@dashboard/dashboard-store';
import { NotificationsService } from '@core/services/helpers/notifications.service';
import { DistanceCalculator } from '@widgets/map-widget/utils/distance-calculator';
import { WeatherDataService } from '@widgets/map-widget/services/weather-data.service';

type TooltipLatLng = [number, number];
export const INITAL_TOOLTIP_POSITION: TooltipLatLng = [-1200, -1200];
export const INITAL_TOOLTIP_POSITION_XY = { x: -500, y: -500 };
type ZoomCb = (zoom: number) => void;

type idCb = (id: string) => void;
type TMarkerDragCb = (id: string, latLng: L.LatLng) => void;

export type LineOpts = {
  lineColor?: string;
  dashArray?: string;
  dashOffset?: string;
  animate?: boolean;
  clickableLine?: boolean;
  clickHandler?: (latlng: LatLng) => void;
};
export type LineMeta = {
  idsBetweenPoints?: [string, string];
};

type TLineCB = (latLng: L.LatLng, meta: LineMeta) => void;

@Injectable({
  providedIn: 'root',
})
export class MapApiService {
  private _notifictions = inject(NotificationsService);

  private _animating = false;
  private _mapClickCb!: (latLng: L.LatLng) => void;
  private _clickCallback!: (id: string) => void;
  private _hoverCallback!: (id: string) => void;
  private _zoomCallback!: ZoomCb;
  private _removeMarkerCallback!: idCb;
  private _dragMarkerCB!: TMarkerDragCb;
  private _clickLineCB!: TLineCB;

  public set RegisterDragMarkerCB(cb: TMarkerDragCb) {
    this._dragMarkerCB = cb;
  }

  public set RegisterMapClickCB(cb: (latLng: L.LatLng) => void) {
    this._mapClickCb = cb;
  }

  public set RegisterClickCallBack(cb: (id: string) => void) {
    this._clickCallback = cb;
  }
  public set RegisterHoverCallBack(cb: (id: string) => void) {
    this._hoverCallback = cb;
  }

  public set SetZoomCallback(cb: ZoomCb) {
    this._zoomCallback = cb;
  }

  public set SetRemoveMarkerCallback(cb: idCb) {
    this._removeMarkerCallback = cb;
  }

  public set SetClickLineCB(cb: TLineCB) {
    this._clickLineCB = cb;
  }

  public get tooltipPosition$() {
    return this._tooltipPosition$;
  }

  private Elements: Record<string, any> = {};
  private _tooltipPosition$: BehaviorSubject<TooltipLatLng>;
  public activeToolTipMarkerId$: BehaviorSubject<string>;
  public activeMarker$: BehaviorSubject<string>;
  public markerClicked$: BehaviorSubject<boolean>;
  public markerHovering$: BehaviorSubject<boolean>;
  public mapClicked$: BehaviorSubject<boolean>;
  public mapClickEvent$: BehaviorSubject<LatLng>;
  public mapZoom$: BehaviorSubject<boolean>;
  public mapDrag$: BehaviorSubject<boolean>;
  public mapMouseMove$: BehaviorSubject<LatLng>;
  public mapLoad$: BehaviorSubject<boolean>;

  @Input() polylineClassName = 'polyline';
  private _map: Map;
  private _mapElements: MapState = {};
  private MarkersGroup = featureGroup();

  private _ActiveTrace: LayerGroup;

  tooltipTimer;

  constructor(
    private mapUtils: MapUtilsService,
    private mapLayer: MapLayersService,
    private _store: Store,
    private _weather: WeatherDataService
  ) {
    this._tooltipPosition$ = new BehaviorSubject(INITAL_TOOLTIP_POSITION);
    this.activeToolTipMarkerId$ = new BehaviorSubject(null);
    this.markerClicked$ = new BehaviorSubject(null);
    this.markerHovering$ = new BehaviorSubject(null);
    this.activeMarker$ = new BehaviorSubject(null);
    this.mapClicked$ = new BehaviorSubject(null);
    this.mapClickEvent$ = new BehaviorSubject(null);
    this.mapZoom$ = new BehaviorSubject(null);
    this.mapDrag$ = new BehaviorSubject(null);
    this.mapMouseMove$ = new BehaviorSubject(null);
    this.mapLoad$ = new BehaviorSubject(false);
  }

  get map() {
    return this._map;
  }

  public removeTooltipPosition() {
    this.tooltipPosition$.next(INITAL_TOOLTIP_POSITION);
    this.markerHovering$.next(false);
  }
  getmapState() {
    return this._mapElements;
  }

  private removeMap() {
    this._map = null;
  }

  public addMarkersToMap() {
    this.MarkersGroup.addTo(this.map);
  }

  public setMap(elementId: string, latLng: [number, number]): Map {
    /**
     * Use the zoomDelta map option to control how many zoom levels the map shall change when using keyboard controls or the zoom buttons, and the wheelPxPerZoomLevel option to control how fast the mouse wheel will change zoom levels.

      Please note that scroll events are finicky, and depend on the web browser and your actual hardware. Some mice scroll in steps, some touchpads report a fine amount of scrolled pixels, some other are just weird. Assume that a "scroll click" is 60 pixels, and adjust the value of wheelPxPerZoomLevel accordingly - e.g. if you want to scroll 5 zoom levels at a time, use a value of (60/5)=12, and a zoomDelta of 5:
     */

    // const ZoomLevelIwantToScroll = 5;
    // const wheelPxPerZoomLevel = 60 / ZoomLevelIwantToScroll;

    const map = lMap(elementId, {
      zoomSnap: 1,
      // zoomDelta: ZoomLevelIwantToScroll,
      // wheelPxPerZoomLevel,
      // minZoom: 3,
      // maxZoom: 9,
      zoomControl: false,
      minZoom: initMapZoom.minZoom,
      maxZoom: initMapZoom.maxZoom,
      // ...initMapZoom,
    })
      .on('load', () => {
        this.mapLoad$.next(true);
      })
      .setView(latLng, initMapZoom.initZoom, { animate: true });

    this.mapLayer.Map = map;

    const southWest = L.latLng(-85, -302);
    const northEast = L.latLng(85, 300);
    const bounds = L.latLngBounds(southWest, northEast);

    this.mapLayer.Initialize();

    map.setMaxBounds(bounds);

    map.on('dragend', function () {
      if (this._animating) return;
      // map.panInsideBounds(bounds, { animate: false });
    });

    map.on('click', (e) => {
      if (this._animating) return;
      this.mapClicked$.next(true);
      this.mapClickEvent$.next(e.latlng);
      this.markerClicked$.next(false);
      this.activeToolTipMarkerId$.next(null);
      this.markerHovering$.next(false);
      if (this._mapClickCb) this._mapClickCb(e.latlng);
    });
    map.on('zoomstart', () => {
      if (this._animating) return;
      this.mapZoom$.next(true);
    });
    map.on('zoom', () => {
      this._zoomCallback(this._map.getZoom());
      if (this._animating) return;
      this.mapZoom$.next(true);
    });
    map.on('zoomend', () => {
      if (this._animating) return;
      this.mapZoom$.next(false);
    });
    map.on('dragstart', () => {
      if (this._animating) return;
      this.mapDrag$.next(true);
    });
    map.on('drag', () => {
      if (this._animating) return;
      this.mapDrag$.next(true);
    });
    map.on('dragend', () => {
      if (this._animating) return;
      this.mapDrag$.next(false);
    });
    map.on('mousemove', (e) => {
      this.mapMouseMove$.next(e.latlng);
    });

    this._map = map;
    this._weather.map = map;

    this.MarkersGroup.addTo(this.map);
    return map;
  }

  public addSimpleMarker(
    id: string,
    dimensions: [number, number],
    latLng: LatLng,
    ele: HTMLElement,
    opts: { removable?: boolean; draggable?: boolean } = {}
  ) {
    const marker = this.mapUtils.HtmlMarker(latLng, dimensions, ele, {
      draggable: opts.draggable,
    });
    marker.addTo(this.map);
    return marker;
  }

  public addmarker(
    id: string,
    _markerOpts: MapMarker,
    htmlIcon: HTMLElement = null,
    latLng: [number, number] = [100000, 100000],
    opts?: { disableTooltip: boolean; removable?: boolean }
  ): L.Marker {
    const { markerType, title, rotationAngle, onHover, onHoverOut, onClick } =
      _markerOpts;

    const position = new L.LatLng(...latLng);

    let marker: Marker = null;
    if (markerType === 'Circle') {
      // marker = this.mapUtils.circleMarker(position);
    } else if (markerType === 'Text') {
      marker = this.mapUtils.labelMarker(id, position, title);
      //TODO:If text title then add a javascript callback
      // on hover to change the width dynamically
    } else {
      marker = this.mapUtils.directionalMarker(
        position,
        htmlIcon || DIRECTIONAL_SVG
      );
    }

    if (onHover)
      marker.on('mouseover', () => {
        !this._animating && onHover();
      });
    if (onHoverOut)
      marker.on('mouseout', () => {
        !this._animating && onHoverOut();
      });
    if (onClick)
      marker.on('click', () => {
        !this._animating && onClick();
      });

    marker.on('click', (e) => {
      if (this._animating) return;

      if (!opts?.disableTooltip) {
        this._clickCallback(id);
        this.markerClicked$.next(true);
        this.activeToolTipMarkerId$.next(id);
        this.activeMarker$.next(id);
        this._tooltipPosition$.next([e.latlng.lat, e.latlng.lng]);
      }

      if (opts?.removable) {
        marker.remove();
        this._removeMarkerCallback(id);
      }
    });
    marker.on('mouseover', (e) => {
      if (this._animating) return;

      if (!opts?.disableTooltip) {
        // this._hoverCallback(id);
        this.activeToolTipMarkerId$.next(id);
        this.markerHovering$.next(true);
        this.markerClicked$.next(false);
        this._tooltipPosition$.next([e.latlng.lat, e.latlng.lng]);
      }
    });

    marker.on('mouseout', () => {
      this.markerHovering$.next(false);
      clearTimeout(this.tooltipTimer);
    });

    marker.setRotationAngle(rotationAngle);
    // marker.setZIndexOffset(500);

    this.MarkersGroup.addLayer(marker);
    this.Elements[id] = marker;
    return marker;
  }

  //TODO: Change name..This is not add line this is an extension of leaflet line.
  public addLine(
    line: MapLine,
    _opts?: {
      dashArray: string;
      dashOffset: string;
      animate?: boolean;
    }
  ): FeatureGroup {
    const { points, colorChangeIndexGroups } = line;
    const lines: Layer[] = [];
    if (colorChangeIndexGroups && colorChangeIndexGroups.length > 0) {
      const polylines = colorChangeIndexGroups.map(
        ([minIndex, maxIndex, _color], groupIndex) => {
          let opts = _opts;
          let color = _color;
          let slicedPoints: [number, number][] = [];
          //we need to check if null.and if null we need to get the last prev points and create a dashed line.
          try {
            if (color === null) {
              const [, firstPos] = colorChangeIndexGroups[groupIndex - 1];
              const [lastpos] = colorChangeIndexGroups[groupIndex + 1];
              slicedPoints = points
                .slice(firstPos, lastpos + 1)
                .filter((d) => !!d);

              const dashOpts = {
                dashArray: '5, 5',
                dashOffset: '2',
              };

              if (!opts) {
                opts = {
                  ...dashOpts,
                };
              } else {
                opts = {
                  ...opts,
                  ...dashOpts,
                };
              }

              color = 'white';
            } else {
              //The next line is to get the points between the min and max index.
              //the problem is that we had empty spaces between lines so we start each line from the previous latest point of the prevous line
              slicedPoints = points
                .slice(minIndex === 0 ? minIndex : minIndex - 1, maxIndex + 1)
                .filter((d) => !!d);
            }
          } catch (error) {
            console.error('trace null values out of bounds');
          }

          return this.mapUtils.line(
            this.polylineClassName,
            slicedPoints,
            color,
            opts
          );
        }
      );
      lines.push(...polylines);
    } else {
      const polyline = this.mapUtils.line(
        this.polylineClassName,
        points,
        'white'
      );
      lines.push(polyline);
    }
    const lineGroup = new FeatureGroup().addLayer(layerGroup(lines));
    return lineGroup;
  }

  public CreateLine(
    path: Array<[number, number]>,
    color: string = 'white',
    opts: {
      dashArray?: string;
      dashOffset?: string;
      animate?: boolean;
      showArrow?: boolean;
    } = {}
  ) {
    const line = this.mapUtils.line(this.polylineClassName, path, color, {
      ...opts,
      weight: 1,
    });

    if (opts.showArrow) {
      const LineGroup = this.GenerateDirectionalLineArrow(line);
      return LineGroup;
    }

    const layerGroup = featureGroup();
    layerGroup.addLayer(line);
    layerGroup.addTo(this.map);
    return layerGroup;
  }

  private GenerateDirectionalLineArrow(line: L.Polyline) {
    const layerGroup = featureGroup();

    layerGroup.addLayer(line);
    layerGroup.addTo(this.map);
    const LineCalculations = (line: L.Polyline) => {
      const distanceInNauticalMiles = calculateDistance(line);
      // Calculate direction (bearing) of the line
      const startPoint = line.getLatLngs()[0] as LatLng;
      const endPoint = line.getLatLngs()[1] as LatLng;
      const angle =
        Math.atan2(
          endPoint.lat - startPoint.lat,
          endPoint.lng - startPoint.lng
        ) *
        (180 / Math.PI);

      const midpoint = line.getCenter();
      return { distanceInNauticalMiles, angle, midpoint };
    };

    const { angle, midpoint } = LineCalculations(line);

    const triangleIcon = L.divIcon({
      className: 'triangle-icon',
      html: '<div class="triangle"></div>',
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });

    const TriangleMarker = L.marker(midpoint, {
      icon: triangleIcon,
      zIndexOffset: 1000,
      interactive: false,
    });

    layerGroup.addLayer(TriangleMarker);
    TriangleMarker.setRotationAngle(90 - angle);
    return layerGroup;
  }

  public addLineInDifferentLayers(
    points: Array<[number, number]>,
    opts: LineOpts = {}
  ): {
    layerGroup: LayerGroup;
    miles: number;
    updateLine: (edge: 0 | 1, newPoint: LatLng) => void;
  } {
    const layerGroup = featureGroup();
    layerGroup.addTo(this.map);

    const { lineColor } = opts;
    const line = this.mapUtils.line(
      this.polylineClassName,
      points,
      lineColor || 'white',
      { ...opts }
    );

    function ClickLineEvent(e: L.LeafletMouseEvent) {
      opts.clickHandler(e.latlng);
      L.DomEvent.stopPropagation(e);
    }

    line.on('click', ClickLineEvent.bind(this));

    layerGroup.addLayer(line);

    const calculations = (line: L.Polyline) => {
      const distanceInNauticalMiles = calculateDistance(line);
      // Calculate direction (bearing) of the line
      const startPoint = line.getLatLngs()[0] as LatLng;
      const endPoint = line.getLatLngs()[1] as LatLng;
      const angle =
        Math.atan2(
          endPoint.lat - startPoint.lat,
          endPoint.lng - startPoint.lng
        ) *
        (180 / Math.PI);

      const midpoint = line.getCenter();
      return { distanceInNauticalMiles, angle, midpoint };
    };

    const generateNayticalMilesIcon = (distance: number) => {
      return L.divIcon({
        className: 'line-miles',
        html: `<div class="miles">${distance.toFixed(2) + ' NM'}</div>`,
        iconSize: [0, 12],
        iconAnchor: [-20, 6],
      });
    };

    const { distanceInNauticalMiles, angle, midpoint } = calculations(line);

    const triangleIcon = L.divIcon({
      className: 'triangle-icon',
      html: '<div class="triangle"></div>',
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });

    const TriangleMarker = L.marker(midpoint, {
      icon: triangleIcon,
      zIndexOffset: 1000, // To ensure the marker is above the polyline
      interactive: false,
    });

    const MilesMarker = L.marker(midpoint, {
      icon: generateNayticalMilesIcon(distanceInNauticalMiles),
      zIndexOffset: 1000, // To ensure the marker is above the polyline
      interactive: false,
    });

    layerGroup.addLayer(TriangleMarker);
    layerGroup.addLayer(MilesMarker);

    TriangleMarker.setRotationAngle(90 - angle);

    return {
      layerGroup,
      miles: distanceInNauticalMiles,
      updateLine: (edge: 0 | 1, newPoint: LatLng) => {
        const [firstPoint, secondPoint] = line.getLatLngs() as LatLng[];
        if (edge === 0) {
          line.setLatLngs([newPoint, secondPoint]);
        } else {
          line.setLatLngs([firstPoint, newPoint]);
        }

        const { distanceInNauticalMiles, angle, midpoint } = calculations(line);
        TriangleMarker.setLatLng(midpoint);
        MilesMarker.setIcon(generateNayticalMilesIcon(distanceInNauticalMiles));
        MilesMarker.setLatLng(midpoint);
        TriangleMarker.setRotationAngle(90 - angle);
        return distanceInNauticalMiles;
      },
    };
  }

  public ExtendTrace(points: Position[], color: PosColor) {
    const line = this.mapUtils.line(this.polylineClassName, points, color);
    if (!this._ActiveTrace) return;
    this._ActiveTrace.addLayer(layerGroup([line]));
  }

  // public inertLinePoint(id: string, point: [number, number]) {
  //   this._StateItemChecker(id, 'trace');
  //   const trace = this._mapElements[id].trace;
  //   trace.eachLayer((layer) => console.log(layer.));

  //   console.log('New line to add', point);
  // }
  public setTrace(trace: LayerGroup) {
    this._ActiveTrace = trace;
    this._ActiveTrace.addTo(this.map);
  }

  // public setTrace(id: string, trace: LayerGroup): MapStateItem {
  //   this._mapElements[id].trace = trace;
  //   const element = this._mapElements[id];
  //   if (element) {
  //     this._mapElements[id].trace = trace;
  //   } else {
  //     this._mapElements[id] = { trace };
  //   }
  //   return this._mapElements[id];
  // }
  public setMarker(id: string, marker: Marker): MapStateItem {
    const element = this._mapElements[id];
    if (element) {
      this._mapElements[id].marker = marker;
    } else {
      this._mapElements[id] = { marker };
    }
    this._mapElements[id]['marker'] = marker;
    return this._mapElements[id];
  }
  public setTitle(id: string, title: Marker): MapStateItem {
    const element = this._mapElements[id];
    if (element) {
      this._mapElements[id].title = title;
    } else {
      this._mapElements[id] = { title };
    }
    this._mapElements[id].title = title;
    return this._mapElements[id];
  }

  public changeTitle(id: string, value: string) {
    const markerCont = this._mapElements[id].title.getElement();
    const titleElement = markerCont.querySelector('.marker-title');
    if (titleElement) titleElement.innerHTML = value;
  }

  public hideTrace(id: string) {
    this._StateItemChecker(id, 'trace');
    this._mapElements[id].trace.removeFrom(this.map);
  }

  public removeTrace() {
    console.debug('Removing Trace');
    if (this._ActiveTrace) this._ActiveTrace.removeFrom(this.map);
  }

  public setMarkerOpacity(id: string, opacity: number, type: MarkerStateType) {
    this._StateItemChecker(id, type);
    this._mapElements[id][type].setOpacity(opacity);
  }
  public changeMarkersOpacity(opacity: number, type: MarkerStateType) {
    const storeItems = this._mapElements;
    for (const key in storeItems) {
      this.setMarkerOpacity(key, opacity, type);
    }
  }
  public setMarkerPosition(id: string, latLng: [number, number]) {
    const position = new LatLng(...latLng);
    const marker = this._mapElements[id].marker;
    const title = this._mapElements[id].title;
    if (marker) marker.setLatLng(position);
    if (title) title.setLatLng(position);
  }

  public setMarkerLat(id: string, lat: number) {
    console.log('id', id);
    this._StateItemChecker(id, 'marker');
    const { lng } = this.getMarkerLatLng(id);
    this.setMarkerPosition(id, [lat, lng]);
  }
  public setMarkerLng(id: string, lng: number) {
    this._StateItemChecker(id, 'marker');
    const { lat } = this.getMarkerLatLng(id);
    this.setMarkerPosition(id, [lat, lng]);
  }
  private getMarkerLatLng(id: string): LatLng {
    this._StateItemChecker(id, 'marker');
    return this._mapElements[id].marker.getLatLng();
  }

  public setMarkerRotation(id: string, deg: number) {
    const marker = this._mapElements[id].marker;
    if (marker) marker.setRotationAngle(deg);
  }

  public setMarkerColor(id: string, color: string) {
    this._StateItemChecker(id, 'marker');
    const marker = this._mapElements[id]['marker'];
    const markerElement: HTMLElement | undefined = marker?.getElement();
    //TODO: For svg items maybe give user the appropriate configuration so he can tell in with svg children id whant to change what property and color...
    //TODO:Change this for non svg items
    const svgItems = markerElement?.querySelector('svg')?.children ?? [];
    for (const key in svgItems) {
      if (Object.prototype.hasOwnProperty.call(svgItems, key)) {
        const svgItem: HTMLElement = svgItems[key] as HTMLElement;
        svgItem.style.fill = color;
      }
    }
  }
  public setMarkerSvgOutline(id: string, color: string) {
    this._StateItemChecker(id, 'marker');
    const marker = this._mapElements[id]['marker'];
    const markerElement: HTMLElement | undefined = marker?.getElement();
    const svgItems = markerElement?.querySelector('svg')?.children ?? [];

    for (const key in svgItems) {
      if (Object.prototype.hasOwnProperty.call(svgItems, key)) {
        const svgItem: HTMLElement = svgItems[key] as HTMLElement;
        svgItem.style.stroke = color;
      }
    }
  }
  private _StateItemChecker(id: string, property: MapStateProps) {
    const stateItem = this._mapElements[id][property];
    if (!stateItem) {
      console.warn(`Missing ${property}`);
      return;
    }
  }

  public resetMap() {
    this.map.off();
    this.map.remove();
    this.MarkersGroup = featureGroup();
    this.removeMap();
  }

  zoom: number;

  public FocusMapMarker(markerId: string) {
    const marker = this.Elements[markerId];

    this._animating = true;

    this._store.pipe(select(ZoomSelector), take(1)).subscribe((zoomLvl) => {
      marker?.getLatLng() &&
        this.map.flyTo(marker.getLatLng(), zoomLvl, {
          animate: false,
        });

      setTimeout(() => {
        this._animating = false;
      }, 1500);
    });
  }

  public fitMapElements(bounds: LatLngBoundsExpression) {
    this.map.flyToBounds(bounds, {
      animate: true,
      duration: 2.5,
    });
  }

  public zoomToMarker(markerId: string) {
    this._StateItemChecker(markerId, 'marker');
    console.log('this._mapElements', this._mapElements);

    const marker = this._mapElements[markerId]['marker'];
    this.map.flyTo(marker.getLatLng(), this.map.getZoom(), {
      animate: false,
      duration: 1,
    });
  }

  setZoomLvl(zoomLvl: number) {
    this.map.setZoom(zoomLvl);
  }

  public AddMapElementToMap(ele: Layer) {
    ele.addTo(this.map);
  }
  public RemoveElementFromMap(ele: Layer) {
    ele.remove();
  }

  public wayPointInfo() {
    const htmlLayer = L.divIcon({
      className: '',
      html: '<div id="custom-html-tooltip" class="bg-white mt-20 rounded-lg text-center shadow-md-25 text-sm w-52 z-50 p-2 pointer-events-none absolute" >Click to place a waypoint marker.</div>',
      iconSize: [200, 0],
      // iconAnchor: [0, 0],
    });
    const htmlMarker = L.marker(this.map.getCenter(), {
      icon: htmlLayer,
      interactive: false,
      zIndexOffset: 1100,
    }).addTo(this.map);

    return htmlMarker;
  }

  public removeAllPopups() {
    this._map.eachLayer(function (layer) {
      if (
        layer instanceof L.Marker ||
        layer instanceof L.Polygon ||
        layer instanceof L.Polyline
      ) {
        // Check if the layer has a popup and close it
        if (layer.getPopup()) {
          layer.closePopup();
        }
      }
    });
  }
}

function calculateDistance(polyline) {
  const latlngs = polyline.getLatLngs();
  let distance = 0;

  for (let i = 1; i < latlngs.length; i++) {
    distance += DistanceCalculator(latlngs[i - 1], latlngs[i]);
  }

  return distance;
}
