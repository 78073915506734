import { createReducer, on } from '@ngrx/store';
import { DashboardData } from '../dashboard.state';

import {
  AddNewTracePointAction,
  InitDashboardStateAction,
  SetVariableAction,
  SetVariablesDataAction,
} from '@dashboard-store';

import { cloneDeep } from 'lodash';

export const DashboardDataState: DashboardData = {};

const dashboardDataReducer = createReducer(
  DashboardDataState,
  on(InitDashboardStateAction, (state, { varState }) => {
    return { ...varState };
  }),
  on(SetVariableAction, (state, { vesselId, tagName, value }) => {
    //DO THIS
    const newState = cloneDeep(state);
    //OR THIS
    //   cloneDeep(state);
    newState[vesselId][tagName] = value;
    return { ...state, ...newState };
  }),
  on(SetVariablesDataAction, (state, { variableMeta }) => {
    const newState = cloneDeep(state);
    for (const [vesselId, tag, value] of variableMeta) {
      try {
        newState[vesselId][tag] = { ...newState[vesselId][tag], ...value };
      } catch (error) {
        console.error('Error setting variable data', { vesselId, tag, value });
      }
    }

    return { ...newState };
  }),
  on(AddNewTracePointAction, (state, { position, selectedImo }) => {
    const trace = cloneDeep(state[selectedImo]?.trace?.values) as string[];

    if (!trace || trace.length == 0) return { ...state };

    trace.unshift(position);

    const newState = cloneDeep(state);

    newState[selectedImo].trace.values = trace;

    return {
      ...newState,
    };
  })
);
export default dashboardDataReducer;
