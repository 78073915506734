type ArrayIndexRange<T> = Array<[number, number, Array<T>]>;

export const getDistinctiveValueChange = <T>(
  data: Array<T>
): ArrayIndexRange<T> => {
  let lastChangedIndex = 0;
  const indexes: ArrayIndexRange<T> = [];
  data.reduce((a, b, index) => {
    if (a !== b) {
      indexes.push([
        lastChangedIndex,
        index,
        data.slice(lastChangedIndex, index),
      ]);
      lastChangedIndex = index;
    }
    return b;
  });
  return indexes;
};

export const groupBy = (arr: any[], ...props: string[]) => {
  return arr.reduce((acc, obj) => {
    const key = props.map((p) => obj[p]).join('-');
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const uniqueData = (arr: any[], props: string[]) => {
  return arr.reduce((accumulator, current) => {
    const index = accumulator.findIndex((item: any) => {
      return props.every((prop) => item[prop] === current[prop]);
    });
    if (index === -1) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
};

export const ArrayToObject = <T>(
  vals: Array<T>,
  key: string
): Record<string, T> =>
  vals.reduce((acc, obj) => {
    acc[obj[key]] = obj;
    return acc;
  }, {});
