import { ExtraStateIds, SystemTags } from '@dashboard/models';
import { DashboardVarValue } from '@dashboard-store';
import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
enum DashboardDataActions {
  INIT_STATE = '[DASHBOARD] INIT_STATE',
  SET_VARIABLE = '[DASHBOARD] SET_VARIABLE',
  PARSE_MULTIPLE_VARIABLES = '[DASHBOARD] PARSE_MULTIPLE_VARIABLES',
  SET_MULTIPLE_VARIABLES = '[DASHBOARD] SET_MULTIPLE_VARIABLES',
  PARSE_DOWNSAMPLE_VARIABLES = '[DASHBOARD] PARSE_DOWNSAMPLE_VARIABLES',
}

const InitDashboardStateAction = createAction(
  DashboardDataActions.INIT_STATE,
  props<{ varState: any }>()
);

const SetVariableAction = createAction(
  DashboardDataActions.SET_VARIABLE,
  props<{ vesselId: string; tagName: SystemTags; value: DashboardVarValue }>()
);

type SetVariablesPayloadType = [
  vesselImo: string,
  SystemTags | ExtraStateIds,
  DashboardVarValue,
];
const SetVariablesDataAction = createAction(
  DashboardDataActions.SET_MULTIPLE_VARIABLES,
  props<{
    variableMeta: SetVariablesPayloadType[];
    source?: 'API' | 'WS';
  }>()
);

const VesselApiActions = createActionGroup({
  source: 'VESSEL_API',
  events: {
    call: emptyProps(),
    success: emptyProps(),
    failure: emptyProps(),
  },
});

export {
  DashboardDataActions,
  InitDashboardStateAction,
  SetVariableAction,
  SetVariablesDataAction,
  SetVariablesPayloadType,
  VesselApiActions,
};
