import {
  TCoursePlaningPayload,
  TCoursePlaningReponse,
} from './../../route-optim-api.service';
import { Waypoint } from '@course-planning-store';
import { createAction, props } from '@ngrx/store';

enum CourseActionTypes {
  ADD_WAYPOINT = '[COURSE PLANNING] ADD_WAYPOINT',
  REMOVE_WAYPOINT = '[COURSE PLANNING] REMOVE_WAYPOINT',
  SET_WAYPOINTS = '[COURSE PLANNING] SET_WAYPOINTS',
  UPDATE_WAYPOINT = '[COURSE PLANNING] UPDATE_WAYPOINT',
  SET_MILES = '[COURSE PLANNING] SET_MILES',
  TOGGLE_WAYPOINT_EDIT_MODE = '[COURSE PLANNING] TOGGLE_WAYPOINT_EDIT_MODE',
  GET_ROUTE_API = '[COURSE API] GET_ROUTE_API',
  GET_ROUTE_API_ERROR = '[COURSE API] GET_ROUTE_API_ERROR',
  SET_RESULTS = '[COURSE API] SET_RESULTS',
  TOGGLE_FROM_CURR = '[COURSE PLANNING] TOGGLE_FROM_CURR',
  CLEAR_WAYPOINTS = '[COURSE PLANNING] CLEAR_WAYPOINTS',
}

const AddWaypointAction = createAction(
  CourseActionTypes.ADD_WAYPOINT,
  props<{ waypoint: Waypoint; onStart?: boolean }>()
);
const RemoveWaypointAction = createAction(
  CourseActionTypes.REMOVE_WAYPOINT,
  props<{ id: string }>()
);

const UpdateWaypoint = createAction(
  CourseActionTypes.UPDATE_WAYPOINT,
  props<{ id: string; lat: number; lng: number }>()
);
const SetWaypointsAction = createAction(
  CourseActionTypes.SET_WAYPOINTS,
  props<{ waypoints: Waypoint[] }>()
);

const clearWaypointsAction = createAction(CourseActionTypes.CLEAR_WAYPOINTS);

const SetMilesAction = createAction(
  CourseActionTypes.SET_MILES,
  props<{ miles: number }>()
);

const ToggleWaypointEditModeAction = createAction(
  CourseActionTypes.TOGGLE_WAYPOINT_EDIT_MODE,
  props<{ enableEdit?: boolean }>()
);

const GetRouteOptApiAction = createAction(
  CourseActionTypes.GET_ROUTE_API,
  props<{ payload: TCoursePlaningPayload }>()
);

const GetRouteOptApiErrorAction = createAction(
  CourseActionTypes.GET_ROUTE_API_ERROR,
  props<{ error: string }>()
);

const SetResultsAction = createAction(
  CourseActionTypes.SET_RESULTS,
  props<{ results: TCoursePlaningReponse }>()
);

const ToggleFromCurrLocAction = createAction(
  CourseActionTypes.TOGGLE_FROM_CURR,
  props<{ fromCurr: boolean }>()
);

export {
  AddWaypointAction,
  RemoveWaypointAction,
  SetWaypointsAction,
  UpdateWaypoint,
  SetMilesAction,
  ToggleWaypointEditModeAction,
  SetResultsAction,
  GetRouteOptApiAction,
  GetRouteOptApiErrorAction,
  ToggleFromCurrLocAction,
  clearWaypointsAction,
};
