import { ApiEffects } from './api.effects';
import { MapEffects } from '@dashboard/dashboard-store/effects/map.effects';
import { TraceEffects } from '@dashboard/dashboard-store/effects/trace.effects';
import { WsEffects } from '@dashboard/dashboard-store/effects/ws.effects';
import { WeatherEffects } from '@dashboard/dashboard-store/effects/weather.effects';
import { SystemEffects } from '@dashboard/dashboard-store/effects/system.effects';
import { FleetEffects } from './fleet.effects';

export default [
  ApiEffects,
  MapEffects,
  TraceEffects,
  WsEffects,
  WeatherEffects,
  SystemEffects,
  FleetEffects,
];
