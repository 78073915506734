import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { ErrorExceptionFragmentsEnum } from 'src/app/modules/auth/auth.fragments';

interface IToken {
  username: string;
  email: string;
  exp: number;
  iat: number;
  name: string;
  role: string;
  _id: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private router: Router) {}

  public login(token: string) {
    localStorage.setItem('token', token);
    this.router.navigate(['']);
  }
  public agentExpiration() {
    // localStorage.removeItem('token');
    this.router.navigate(['/auth/agents-expiration']);
  }
  public expiration(fragment: ErrorExceptionFragmentsEnum) {
    console.log('REDIRECT TO THINGS PAGE', fragment);
    // localStorage.removeItem('token');
    this.router.navigate(['/auth/expiration']);
  }

  public resetPassword(response: { jwt: string; message: any }) {
    const token = response?.jwt;
    if (!token) {
      this.logout();
      return;
    }
    localStorage.setItem('token', token);
    this.router.navigate(['/auth/reset']);
  }
  public removeToken() {
    localStorage.removeItem('token');
  }
  public async logout() {
    this.removeToken();
    this.router.navigate(['auth/login']);
  }
  //
  public get jwtData(): IToken {
    const token = localStorage.getItem('token');
    return jwtDecode<IToken>(token);
  }
  public get role(): string {
    return this.jwtData.role;
  }
  public get username(): string {
    return this.jwtData.username;
  }

  public get userId(): string {
    return this.jwtData._id;
  }
  public getToken(): string {
    return localStorage.getItem('token');
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');

    if (!token) return false;
    return !this.ΙsTokenExpired(token);
  }

  private ΙsTokenExpired(token: string): boolean {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp === undefined) return null;

      const date = new Date(0);
      const tokenExpDate = date.setUTCSeconds(decoded.exp); // convert exp to milliseconds
      // If token expired date is less than the current date
      if (tokenExpDate.valueOf() > new Date().valueOf()) {
        return false;
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
}
