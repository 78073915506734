import { createAction, props } from '@ngrx/store';

enum DashboardApiActions {
  API_GET_TOOLTIP = '[DASHBOARD] API_GET_TOOLTIP',
}

const GetTooltipDataAction = createAction(
  DashboardApiActions.API_GET_TOOLTIP,
  props<{
    vesselId: string;
  }>()
);

export { DashboardApiActions, GetTooltipDataAction };
