import {
  AddWaypointAction,
  GetRouteOptApiAction,
  RemoveWaypointAction,
  SetMilesAction,
  SetResultsAction,
  SetWaypointsAction,
  ToggleFromCurrLocAction,
  ToggleWaypointEditModeAction,
  UpdateWaypoint,
} from '@course-planning-store';
import { createReducer, on } from '@ngrx/store';
import { IStoreCourseState } from '@shared/modules/course-planning/store/course-state.model';

const initialState: IStoreCourseState = {
  waypoints: [],
  calculatedMiles: 0,
  waypointEditing: false,
  loading: false,
  results: { dt: [], foc: [], segments: [], speed: [], summary: [], ws: [] },
  fromCurr: true,
};
const CourseReducers = createReducer(
  initialState,
  on(AddWaypointAction, (state, { waypoint, onStart = false }) => {
    if (onStart) {
      const waypoints = [waypoint, ...state.waypoints];
      return { ...state, waypoints };
    } else {
      const waypoints = [...state.waypoints, waypoint];
      return { ...state, waypoints };
    }
  }),
  on(RemoveWaypointAction, (state, { id }) => {
    const waypoints = state.waypoints.filter((w) => w.id !== id);
    return { ...state, waypoints };
  }),
  on(SetMilesAction, (state, { miles }) => {
    return { ...state, calculatedMiles: miles };
  }),

  on(SetWaypointsAction, (state, { waypoints }) => {
    return { ...state, waypoints };
  }),
  on(ToggleWaypointEditModeAction, (state, { enableEdit }) => {
    let newToggleState = !state.waypointEditing;

    if (enableEdit !== undefined) newToggleState = enableEdit;

    return { ...state, waypointEditing: newToggleState };
  }),

  on(UpdateWaypoint, (state, { id, lat, lng }) => {
    const pointIndex = state.waypoints.findIndex((w) => w.id === id);
    if (pointIndex === -1) return { ...state };

    const waypoints = [...state.waypoints];
    waypoints[pointIndex] = { ...waypoints[pointIndex], lat, lng };
    return { ...state, waypoints };
  }),
  on(GetRouteOptApiAction, (state) => {
    return { ...state, loading: true };
  }),
  on(SetResultsAction, (state, { results }) => {
    return {
      ...state,
      results,
      loading: false,
    };
  }),

  on(ToggleFromCurrLocAction, (state, { fromCurr }) => {
    return {
      ...state,
      fromCurr,
    };
  })
);

export { CourseReducers };
