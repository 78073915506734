import { NgModule } from '@angular/core';
import { UiPartsModule } from '@shared/modules/ui/ui-parts/ui-parts.module';
import { RouterModule } from '@angular/router';
import { AppVersionComponent } from './app-version/app-version.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { BugReportComponent } from './bug-report/bug-report.component';
import { NgxEditorModule } from 'ngx-editor';

@NgModule({
  declarations: [AppVersionComponent, CopyrightComponent, BugReportComponent],
  imports: [UiPartsModule, RouterModule, NgxEditorModule],
  exports: [CopyrightComponent, BugReportComponent],
})
export class ConfigSharedComponentsModule {}
