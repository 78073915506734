import { Injectable } from '@angular/core';
import { ShipTwinSmartAsset } from '@core/constants/app-state.defaults';
import { AppState, IAppStatus } from '@core/models/application.interface';
import { GlobalSettings } from '@core/models/GlobalSettings.model';
import { BehaviorSubject } from 'rxjs';
import { IDashboardResponse } from '../shared/modules/dynamic-dashboards/models/dashboard/dashboard.types';
import { EnvVarsService } from 'src/app/env-vars.service';
import { IVessel } from '@dashboard/models';

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  $appState: BehaviorSubject<AppState>;
  $isDark: BehaviorSubject<boolean>;
  $sites: BehaviorSubject<IVessel[]>;
  $applicationStatus: BehaviorSubject<IAppStatus>;
  $settings: BehaviorSubject<GlobalSettings>;
  $latestDashboardConfig: BehaviorSubject<IDashboardResponse>;

  private _$isDeveloperMode: BehaviorSubject<boolean>;

  constructor(private environment: EnvVarsService) {
    this.$isDark = new BehaviorSubject(false);
    this.$appState = new BehaviorSubject({
      smartAsset: ShipTwinSmartAsset,
      logos: this.environment.getEnvKey('logos'),
      companyName: this.environment.getEnvKey('companyName'),
    });
    this.$sites = new BehaviorSubject([]);
    this.$settings = new BehaviorSubject(new GlobalSettings({}));
    this.$applicationStatus = new BehaviorSubject(<any>{});
    this.$latestDashboardConfig = new BehaviorSubject(<IDashboardResponse>{});
    this._$isDeveloperMode = new BehaviorSubject(false);
  }

  get $isDeveloperMode() {
    return this._$isDeveloperMode.asObservable();
  }

  get latestVersionNumber(): string {
    const versions = this.$settings.getValue().versions;
    if (versions && versions.length > 0) return versions[0].versionNumber;
    return '';
  }
  public ToggleDevelopMode() {
    const currentValue = this._$isDeveloperMode.value;
    this._$isDeveloperMode.next(!currentValue);
  }
  public addSites(site: IVessel) {
    this.$sites.next(this.$sites.getValue().concat([site]));
  }
  public updateSettings(data: any) {
    const settings = this.$settings.getValue();
    if (data.alarms) settings['alarms'] = data.alarms;
    if (data.snmp) settings['snmp'] = data.snmp;
    if (data.tables) settings['tables'] = data.tables;
    if (data.reports)
      settings['reports'] = { ...settings['reports'], ...data.reports };

    this.$settings.next(settings);
    console.log('Set new settings', this.$settings.getValue());
  }

  /**
   * Show or hide a site from lists.
   * @param siteId :string
   * @param newHiddenStatus: Boolean
   * @returns Void
   */
  public toggleSite(siteId: string, newHiddenStatus: boolean): void {
    const sites = this.$sites.getValue();
    const site = sites.find((site) => site._id === siteId);
    const siteIndex = sites.findIndex((site) => site._id === siteId);
    if (!site) return null;
    site['hidden'] = newHiddenStatus;
    sites[siteIndex] = site;
    this.$sites.next(sites);
  }
  public removeSite(siteId: string) {
    const sites = this.$sites.getValue();
    const site = sites.find((site) => site._id === siteId);
    const siteIndex = sites.findIndex((site) => site._id === siteId);
    if (!site) return null;
    sites.splice(siteIndex, 1);
    this.$sites.next(sites);
  }
}
