import { Injectable } from '@angular/core';
import { adjustTimeToTargetTimezone } from '@core/constants/time.defaults';
import moment, { DurationInputArg2, unitOfTime } from 'moment';
import { BehaviorSubject, combineLatest, debounceTime, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimelineService {
  public timezone$ = new BehaviorSubject<number>(0);
  public dateTo$ = new BehaviorSubject<Date | null>(null);
  public dateFrom$ = new BehaviorSubject<Date>(this.GetTzDateNow());
  public stepSize$ = new BehaviorSubject<IStep>(offsetSteps['d']);
  public offsetRange$ = new BehaviorSubject<number>(4); // Subject to handle debouncing
  public isRealtime$ = new BehaviorSubject(true);
  public offsetStep$ = new BehaviorSubject<unitOfTime.Diff>('d');

  public dateOptionsArray$: BehaviorSubject<DateOptions>;

  public ReferenceDate$: BehaviorSubject<Date>;
  //It is requred in order to produce the dates left and right of the selected date.
  public currentDate$ = new BehaviorSubject(this.GetTzDateNow());

  constructor() {
    this.dateOptionsArray$ = new BehaviorSubject([]);
    this.dateTo$ = new BehaviorSubject(this.currentDate$.getValue());
    this.ReferenceDate$ = new BehaviorSubject(this.currentDate$.getValue());

    const minuteMiliSeconds = 60000;
    const rangeToEndOfMin = moment().endOf('minute').diff(moment());

    /**
     * This timer will start at the end of the current minute and will emit every minute.
     * It will update the currentDate variable.
     * currentDate$ is a variable that holds the current user system date. it should be set only in this timer.
     */
    timer(rangeToEndOfMin, minuteMiliSeconds).subscribe(() => {
      this.currentDate$.next(this.GetTzDateNow());
      this.isRealtime$.getValue() &&
        this.ReferenceDate$.next(this.currentDate$.getValue());
      this.generateOptions();
    });
  }

  public get TestLogs() {
    return combineLatest({
      tz: this.timezone$,
      array: this.dateOptionsArray$,
      isRealTime: this.isRealtime$,
      fromDate: this.dateFrom$,
      toDate: this.dateTo$,
      current: this.currentDate$,
      ref: this.ReferenceDate$,
    }).pipe(debounceTime(200));
  }
  generateOptions() {
    const stepSize = this.stepSize$.getValue();
    const refDate = this.ReferenceDate$.getValue();
    const tempArray: DateOptions = [];
    const currentDate = this.currentDate$.getValue();

    for (let index = -3; index <= 3; index++) {
      //THese dates are going to be used allongsize with angular date pipe so we need to convert them to date.
      //But also in clints timezone. Right now refDate is in ??
      //TODO: Is this requeried? i think refDate is have values since it is set in the constructor
      const date = moment(refDate || currentDate).add(index, stepSize.name);

      tempArray.push(date.toDate());
    }
    // console.log({ refDate, currentDate, tempArray });
    this.dateOptionsArray$.next(tempArray);

    return tempArray;
  }

  public stepsArray = Object.values(offsetSteps);

  isPast(date: Date) {
    const currentTimestamp = this.GetTzDateNow();

    const isInCurrentMinute = moment(date).isSame(
      moment(currentTimestamp),
      'minute'
    );

    this.isRealtime$.next(isInCurrentMinute);
    return !isInCurrentMinute;
  }

  setDate(date: Date | null, type?: 'next' | 'prev') {
    const toDate = this.dateTo$.getValue();

    //? Offset Rnage beack and forth
    // const fromDate = this.dateFrom$.getValue();

    // const diff = moment(toDate).diff(
    //   moment(fromDate),
    //   this.stepSize$.getValue().name
    // );

    const diff = 1;
    const stepSize = this.stepSize$.getValue();
    let tempDate: Date;

    switch (type) {
      case 'next':
        tempDate = moment(toDate).add(1, stepSize.name).toDate();
        break;

      case 'prev':
        tempDate = moment(toDate).subtract(1, stepSize.name).toDate();
        break;

      default:
        tempDate = date;
        break;
    }

    this.ReferenceDate$.next(tempDate);
    this.dateTo$.next(tempDate);
    this.dateFrom$.next(
      moment(tempDate).subtract(diff, stepSize.name).toDate()
    );

    this.generateOptions();
    this.isPast(tempDate);
    this.findSetStepRange();
  }

  setStepSize(step: IStep) {
    this.stepSize$.next(step);

    this.generateOptions();
  }

  setToDate(date: Date) {
    this.dateTo$.next(date);

    this.isPast(date);
    this.findSetStepRange();
    // this.generateOptions();
  }

  setFromDate(date: Date) {
    this.dateFrom$.next(date);

    this.findSetStepRange();
    this.generateOptions();
  }

  findSetStepRange() {
    // Calculate the difference in years, months, days
    const yearsDiff = moment(this.dateTo$.getValue()).diff(
      this.dateFrom$.getValue(),
      'y'
    );
    const monthsDiff = moment(this.dateTo$.getValue()).diff(
      this.dateFrom$.getValue(),
      'M'
    );
    const daysDiff = moment(this.dateTo$.getValue()).diff(
      moment(this.dateFrom$.getValue()),
      'd'
    );
    const hoursDiff = moment(this.dateTo$.getValue()).diff(
      this.dateFrom$.getValue(),
      'h'
    );
    const minutesDiff = moment(this.dateTo$.getValue()).diff(
      this.dateFrom$.getValue(),
      'm'
    );

    // Determine the scale based on the difference
    if (yearsDiff >= 1) {
      this.offsetRange$.next(yearsDiff);
      this.offsetStep$.next('y');
      this.stepSize$.next(offsetSteps['y']);
    } else if (monthsDiff >= 1) {
      this.offsetRange$.next(monthsDiff);
      this.offsetStep$.next('M');
      this.stepSize$.next(offsetSteps['M']);
    } else if (daysDiff >= 1) {
      this.offsetRange$.next(daysDiff);
      this.offsetStep$.next('d');
      this.stepSize$.next(offsetSteps['d']);
    } else if (hoursDiff >= 1) {
      this.offsetRange$.next(hoursDiff);
      this.offsetStep$.next('h');
      this.stepSize$.next(offsetSteps['h']);
    } else if (minutesDiff >= 1) {
      this.offsetRange$.next(minutesDiff);
      this.offsetStep$.next('m');
      this.stepSize$.next(offsetSteps['m']);
    }
  }

  public GetTzDateNow() {
    return adjustTimeToTargetTimezone(
      new Date(),
      null,
      this.timezone$.getValue()
    ).getDate();
  }
}

export type Steps = Record<any, IStep>;
export type StepSizes = DurationInputArg2;
export interface IStep {
  name: StepSizes;
  format: string;
  splitChar: ':' | '/';
  tooltip: string;
  fullName: 'month' | 'day' | 'year' | 'minute' | 'hour';
}

export type DateOptions = Date[];

export const offsetSteps: Steps = {
  d: {
    name: 'd',
    format: 'd/MMM',
    splitChar: '/',
    tooltip: 'EEEE, HH:mm',
    fullName: 'day',
  },
  M: {
    name: 'M',
    format: 'MMM/YY',
    splitChar: '/',
    tooltip: 'EEEE d, HH:mm',
    fullName: 'month',
  },
  y: {
    name: 'y',
    format: 'YYYY',
    splitChar: '/',
    tooltip: 'd/MMM, HH:mm',
    fullName: 'year',
  },
  h: {
    name: 'h',
    format: 'HH:mm',
    splitChar: ':',
    tooltip: 'EEEE d/MMM/YY',
    fullName: 'hour',
  },
  m: {
    name: 'm',
    format: 'HH:mm',
    splitChar: ':',
    tooltip: 'EEEE d/MMM/YY',
    fullName: 'minute',
  },
};
