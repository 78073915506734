import { Injectable } from '@angular/core';
import { CommonApiService } from '@core/api/common.api.service';
import { Observable } from 'rxjs';
import { EnvVarsService } from 'src/app/env-vars.service';

@Injectable({
  providedIn: 'root',
})
export class TooltipsApiService {
  private API_URL = this.environment.getEnvKey('API_URL');
  private namespace = 'tooltips';
  private url = `${this.API_URL}/${this.namespace}`;

  constructor(
    private common: CommonApiService,
    private environment: EnvVarsService
  ) {}

  public getTooltip(): Observable<{ value: string }> {
    return this.common.get<{ value: string }>(`${this.url}/system`);
  }
}
