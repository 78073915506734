import { AggregationStepDashboard } from '@dashboard/dashboard-store';

const calcAggr = (aggStep: number, aggType: AggregationStepDashboard): number =>
  ({
    m: aggStep,
    h: aggStep * 60,
    D: aggStep * 60 * 24,
  })[aggType];

export default calcAggr;
