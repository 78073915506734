import { Injectable, inject } from '@angular/core';
import {
  NzNotificationDataOptions,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private static notificationStyling: NzNotificationDataOptions = {
    nzClass: 'rounded-2xl shadow-notification-shadow',
    nzPlacement: 'top',
    nzDuration: 3000,
  };
  private _zorroNotificationService = inject(NzNotificationService);

  public error(title = 'Error', message = '') {
    return this._zorroNotificationService.error(
      title,
      message,
      NotificationsService.notificationStyling
    );
  }

  public info(title = 'Info', message = '') {
    return this._zorroNotificationService.info(
      title,
      message,
      NotificationsService.notificationStyling
    );
  }

  public succcess(title = 'Success', message = '') {
    return this._zorroNotificationService.success(
      title,
      message,
      NotificationsService.notificationStyling
    );
  }

  public removeAllNotifications(id: string = null) {
    this._zorroNotificationService.remove(id);
  }
}
