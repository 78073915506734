import { inject, Injectable } from '@angular/core';
import { ClusteringRequest } from '@core/api/naviqore-clustering-data-api.service';
import { ExportGraphRequest } from '@core/api/naviqore-data-api.service';
import { StateApiService } from '@core/api/state-api.service';
import { ApiTimeUTC, DateToItsTimezone } from '@core/constants/time.defaults';
import {
  InfoWindowIsActiveSelector,
  ToDateSelector,
  SelectedVesselImo,
  SetSelectedVesselAction,
  VesselsSelector,
  SetVariablesDataAction,
  SetVesselWindowAction,
  SetSystemDatesAction,
  SetAggregationStepAction,
  SetAggregationTypeAction,
  AggregationStepSelector,
  AggregationTypeSelector,
  FromDateSelector,
  GetTooltipDataAction,
  VesselApiActions,
  SetTimezoneAction,
  TimezoneSelector,
} from '@dashboard-store';
import { TimelineService } from '@dashboard/components/map-timeline/timeline.service';
import { StoreInitService } from '@dashboard/dashboard-library/helpers/store-init.service';
import { ApiEffectsHelperService } from '@dashboard/dashboard-store/effects/api-effects-helper.service';
import {
  DataSeriesVariable,
  DataToApiService,
} from '@dashboard/services/data-to-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import calcAggr from '@shared/utils/calcAggregation';
import {
  catchError,
  EMPTY,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
@Injectable()
export class ApiEffects {
  private _dataToApi = inject(DataToApiService);
  private actions$ = inject(Actions);
  private _store = inject(Store);
  private _storeInitHelper = inject(StoreInitService);
  private _stateApi = inject(StateApiService);
  private _timeline = inject(TimelineService);
  private _apiEffectHelper = inject(ApiEffectsHelperService);

  ApplyTimezone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetTimezoneAction),
      withLatestFrom(
        this._store.select(ToDateSelector),
        this._store.select(FromDateSelector)
      ),
      switchMap(([{ timezone }, _toDate, _fromDate]) => {
        const toDate = DateToItsTimezone(_toDate, timezone);
        const fromDate = DateToItsTimezone(_fromDate, timezone);

        return of(SetSystemDatesAction({ toDate, fromDate }));
      })
    );
  });

  //This effect works when we have a selected IMO

  trigerLoadVesselData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SetSelectedVesselAction,
        SetSystemDatesAction,
        SetVesselWindowAction,
        SetAggregationStepAction,
        SetAggregationTypeAction
      ),

      mergeMap(() => {
        return of(VesselApiActions.call());
      })
    )
  );

  //This effect works when we have a selected IMO
  loadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VesselApiActions.call),
      withLatestFrom(
        this._store.select(ToDateSelector),
        this._store.select(FromDateSelector),
        this._store.select(SelectedVesselImo),
        this._store.select(InfoWindowIsActiveSelector),
        this._store.select(AggregationStepSelector),
        this._store.select(AggregationTypeSelector),
        this._store.select(TimezoneSelector)
      ),
      switchMap(
        ([
          ,
          toDate,
          fromDate,
          selectedImo,
          infoIsActive,
          aggStep,
          aggType,
          timezoneHours,
        ]) => {
          const queryDataVars: DataSeriesVariable[] = [];
          const clusteringVars: DataSeriesVariable[] = [];

          if (!selectedImo) return EMPTY;

          const sidebardData = this._dataToApi.SidebarQueryPayload();
          queryDataVars.push(...sidebardData);

          if (infoIsActive) {
            const windoInfoData = this._dataToApi.WindowInfoDataQueryPayload();
            const clusteringData =
              this._dataToApi.WindowInfoClusteringQueryPayload();
            clusteringVars.push(...clusteringData);
            queryDataVars.push(...windoInfoData);
          }

          const queryDataPayload: ExportGraphRequest = {
            start: ApiTimeUTC(fromDate),
            end: ApiTimeUTC(toDate),
            variables: queryDataVars.map((v) => ({ tagId: Number(v.tagId) })),
            filters: [],
          };

          const clusteringPayload: ClusteringRequest = {
            start: ApiTimeUTC(fromDate),
            end: ApiTimeUTC(toDate),
            variables: clusteringVars.map((v) => {
              let obj = { tagId: Number(v.tagId) };

              if (v.extraPayloadProps) {
                obj = { ...obj, ...v.extraPayloadProps };
              }
              return obj;
            }),
            filters: [],
          };

          if (aggStep)
            queryDataPayload['aggregation'] = calcAggr(aggStep, aggType);

          return this._apiEffectHelper
            .ApiDataRequestsToState(
              selectedImo,
              toDate,
              { queryDataPayload, clusteringPayload },
              { queryDataVars, clusteringVars, timezoneHours }
            )
            .pipe(
              switchMap(([a, b]) => {
                return of(
                  SetVariablesDataAction({
                    variableMeta: [...a, ...b],
                  }),
                  VesselApiActions.success()
                );
              }),
              catchError((error) => {
                console.error('Error in API request:', error);
                return of(VesselApiActions.failure());
              })
            );
        }
      )
    );
  });

  loadTooltipData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetTooltipDataAction),
      withLatestFrom(
        this._store.select(VesselsSelector),
        this._store.select(ToDateSelector)
      ),
      switchMap(([{ vesselId }, vessels, date]) => {
        const isRealtime = this._timeline.isRealtime$.getValue();
        const _date = isRealtime ? 'latest' : date;
        const imo = vessels.find((v) => v._id === vesselId).slug;

        return this._stateApi.getVesselState(imo, _date).pipe(
          map((response) => {
            const variableMeta =
              this._storeInitHelper.VesselStateDataToActionData(response);

            return SetVariablesDataAction({ variableMeta });
          })
        );
      })
    );
  });
}
