// export const DIRECTIONAL_SVG = `<svg width="30" height="30" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve">
//   <ellipse stroke="#000" stroke-width="15" ry="98" rx="98" id="svg_21" cy="172.82001" cx="153.82" fill="#fff"/>
//   <path transform="rotate(90 313.82 169.689)" stroke="#000" stroke-width="15" id="svg_22" d="m258.82002,217.81401l54.99998,-96.24997l54.99998,96.24997l-109.99996,0z" fill="#fff"/>
// </svg>`;

// export const DIRECTIONAL_SVG = `<svg width="30" height="30" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550.06 265.27">
//   <defs>
//     <style>
//       .cls-1 {
//         fill: #5151a7;
//         stroke: white;
//         stroke-miterlimit: 10;
//         stroke-width: 6px;
//       }
//     </style>
//   </defs>
//   <g id="Layer_1-2" data-name="Layer 1">
//     <g>
//       <path class="cls-1" d="m545.67,132.88c-22.27-20.53-51.37-43.42-88-64C383.14,27,312.4,14.33,267,10.21c-36.09-3.3-74.38-5.66-114.67-6.67-53.16-1.33-103.1-.15-149.33,2.67v250.67c37.57,3.6,79.09,5.77,124,5.33,54.23-.52,103.65-4.74,147.33-10.67,42.14-3.02,110.08-13.71,182-53.33,38.04-20.96,67.53-44.69,89.33-65.33Z"/>
//       <path class="cls-1" d="m375.44,59.55c26.46,7.14,59.96,19.32,95.11,40.89,17.24,10.58,32,21.64,44.44,32.2-11.14,9.89-24.13,20.14-39.11,30.02-36.82,24.29-72.5,37.67-100.44,45.33"/>
//       <path class="cls-1" d="m387.89,77.32c-22.31-9.07-49.44-18.16-80.89-24.89-39.17-8.38-74.08-10.74-102.22-10.67v183.11c28.13-.17,61.93-2.54,99.56-9.78,32.06-6.17,60.08-14.55,83.56-23.11v-114.67Z"/>
//       <line class="cls-1" x1="257.83" y1="41.77" x2="257.83" y2="224.88"/>
//       <line class="cls-1" x1="375.44" y1="114.66" x2="274.33" y2="114.66"/>
//       <line class="cls-1" x1="375.44" y1="133.77" x2="274.33" y2="133.77"/>
//       <line class="cls-1" x1="375.44" y1="155.55" x2="274.33" y2="155.55"/>
//       <polygon class="cls-1" points="170.11 47.1 170.11 24.88 11.89 24.88 11.89 240.88 170.11 240.88 170.11 219.55 181.67 213.32 181.67 53.32 170.11 47.1"/>
//       <path class="cls-1" d="m169.78,47.1c-2.63-1.69-7.17-4.15-13.33-5.33-6.09-1.17-11.16-.59-14.22,0-1.09-.1-2.78-.14-4.74.3-9.81,2.19-12.73,13.4-22.66,25.31-2.84,3.41-7.18,7.98-13.49,12.61-2.55.73-5.54,1.39-8.89,1.78-5.97.7-11.14.32-15.11-.3l-27.56,2.06c-8,.6-16,1.2-24,1.79v97.78c6.41-.21,14.05.07,22.52,1.48,4.77.79,9.13,1.83,13.04,2.96h36.44c2.15.36,6.76,1.42,11.26,5.04,4.58,3.68,6.61,8.04,7.41,10.07.66,2.64,2.69,9.28,8.89,14.81,7.94,7.08,17.16,7.4,19.56,7.41,1.44.72,7.32,3.46,14.52,1.48,5.83-1.6,9.21-5.39,10.37-6.81"/>
//       <line class="cls-1" x1="153.11" y1="118.66" x2="52" y2="118.66"/>
//       <line class="cls-1" x1="153.11" y1="137.77" x2="52" y2="137.77"/>
//       <line class="cls-1" x1="153.11" y1="159.55" x2="52" y2="159.55"/>
//     </g>
//   </g>
// </svg>`;

export const DIRECTIONAL_SVG = `<svg width="34" height="14" viewBox="0 0 44 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_151_51)">
<path id="ship-svg" d="M1.07948 13.38L1.48185 13.5115L1.28466 14.1149C2.05493 14.3237 2.82167 14.5448 3.58459 14.7782L3.72035 14.8197L3.76669 14.85C3.77851 14.8535 3.79296 14.8576 3.81035 14.8623C3.87829 14.8806 3.94939 14.8977 4.03432 14.9182C4.07393 14.9277 4.11653 14.938 4.16323 14.9494L4.19435 14.9571L4.22492 14.9667C5.20517 15.2747 6.21022 15.5013 7.22945 15.6442L7.27379 15.6504L7.3174 15.6605C14.2123 17.2658 21.3851 17.3489 28.3168 15.9038L28.3168 15.9038L28.3237 15.9024C29.7733 15.6109 32.8921 14.5785 34.3354 14.0249L34.3451 14.0212L34.3549 14.0177C34.6423 13.9142 35.087 13.7154 35.4865 13.528C35.6804 13.4371 35.8542 13.3533 35.9795 13.2923C36.0421 13.2618 36.0924 13.2371 36.1268 13.2202L36.166 13.2008L36.1758 13.196L36.178 13.1948L36.1785 13.1946L36.1785 13.1946L36.1786 13.1946L36.1786 13.1945L36.1857 13.191L36.193 13.1875L36.193 13.1875L36.193 13.1875L36.193 13.1875L36.1932 13.1874L36.1943 13.1869L36.199 13.1846L36.2181 13.1754L36.2899 13.1408C36.351 13.1112 36.4353 13.07 36.5283 13.0239C36.7216 12.9282 36.9279 12.8231 37.0488 12.7545L37.0488 12.7544L37.0605 12.7479L38.3456 12.0411C39.8387 11.1616 41.2248 10.121 42.4782 8.93948C42.1661 8.65682 41.8556 8.37238 41.5468 8.08616C38.3327 5.579 34.6315 3.73691 30.6673 2.67269L30.6523 2.66864L30.6373 2.66412C26.8765 1.52746 22.952 0.996724 19.0172 1.0928L18.9976 1.09328L18.9779 1.09298C16.913 1.06231 15.8676 1.09303 14.6425 1.18291C10.0282 1.59785 5.48014 2.53991 1.0907 3.98933C1.0839 4.28336 1.07803 4.66216 1.07313 5.10317C1.06131 6.16741 1.05539 7.57647 1.05539 8.98835C1.05539 10.4002 1.06131 11.8112 1.07313 12.8794C1.07509 13.0567 1.07721 13.224 1.07948 13.38Z" fill="#60B057" stroke="white" stroke-width="2"/>
<ellipse id="ship-speed-1" cx="12.1734" cy="8.69996" rx="2.7" ry="2.64" transform="rotate(-90 12.1734 8.69996)" fill="white"/>
<ellipse id="ship-speed-2" cx="26.6935" cy="8.69997" rx="2.7" ry="2.64" transform="rotate(-90 26.6935 8.69997)" fill="white"/>
<ellipse id="ship-speed-3" cx="19.4335" cy="8.69996" rx="2.7" ry="2.64" transform="rotate(-90 19.4335 8.69996)" fill="white"/>
</g>
<defs>
<clipPath id="clip0_151_51">
<rect width="18" height="44" fill="white" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 44 18)"/>
</clipPath>
</defs>
</svg>
`;
