import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  defaultMemoize,
} from '@ngrx/store';
import {
  DashboardVesselData,
  StoreDashboardState,
  TDownsampleChartPoint,
} from '../dashboard.state';
import { isEqual } from 'lodash';
import { SelectedVesselImo, VesselImoSelector } from '@dashboard-store';
import { ExtraStateIds, SystemTags } from '@dashboard/models';

const dataState = createFeatureSelector<StoreDashboardState>('dashboard');
export const DeepSelector = createSelectorFactory((projectionFn) =>
  defaultMemoize(
    projectionFn,
    arraysAndObjectsComparison,
    arraysAndObjectsComparison
  )
) as typeof createSelector;

function arraysAndObjectsComparison(a: any, b: any): boolean {
  const res = isEqual(a, b);
  return res;
}

const variableSelector = (
  tagName: SystemTags | ExtraStateIds,
  vesselId: string = null
) =>
  createSelector(
    dataState,
    SelectedVesselImo,
    VesselImoSelector(vesselId),
    (dataState, selectedImo, imo) => {
      const _imo = imo ?? selectedImo;
      if (!_imo) return '';
      return dataState.data[_imo][tagName].formattedValue;
    }
  );

const variablePreviewSelector = (
  tagName: SystemTags | ExtraStateIds,
  vesselId: string = null
) =>
  createSelector(
    dataState,
    SelectedVesselImo,
    VesselImoSelector(vesselId),
    (dataState, selectedImo, imo) => {
      const _imo = imo ?? selectedImo;
      if (_imo) {
        const val = { ...dataState.data[_imo][tagName] };
        return {
          ...val,
          value: dataState.data[_imo][tagName].formattedValue,
        };
      }
      return null;
    }
  );

const variableObjectSelector = (
  tagName: SystemTags | ExtraStateIds,
  vesselId: string = null
) =>
  createSelector(
    dataState,
    SelectedVesselImo,
    VesselImoSelector(vesselId),
    (dataState, selectedImo, imo) => {
      const _imo = imo ?? selectedImo;
      if (!_imo) return null;
      const t = dataState.data[_imo][tagName];
      return t;
    }
  );

const MultipleVariableSelector = (
  tagNames: (SystemTags | ExtraStateIds)[],
  vesselId: string = null
) =>
  DeepSelector(
    dataState,
    SelectedVesselImo,
    VesselImoSelector(vesselId),
    (dataState: StoreDashboardState, selectedImo: string, imo: string) => {
      const _imo = imo ?? selectedImo;
      if (!_imo) return '';
      return tagNames.reduce((prev, tag) => {
        return {
          ...prev,
          [tag]: dataState.data[_imo][tag],
        };
      }, {} as DashboardVesselData);
    }
  ) as MemoizedSelector<StoreDashboardState, DashboardVesselData>;

const DownsampleVariableSelector = (tagName: SystemTags | ExtraStateIds) =>
  DeepSelector(dataState, SelectedVesselImo, (dataState, imo) => {
    if (!imo) return [];
    return dataState.data[imo][tagName].values;
  }) as MemoizedSelector<StoreDashboardState, TDownsampleChartPoint[]>;

const VesselDataSelector = (vesselId: string = null) =>
  DeepSelector(
    dataState,
    SelectedVesselImo,
    VesselImoSelector(vesselId),
    (dataState, selectedImo, imo) => {
      const _imo = imo ?? selectedImo;
      return dataState.data[_imo];
    }
  );

const ProgressBarSelector = (vesselId: string = null) =>
  DeepSelector(dataState, SelectedVesselImo, (dataState, imo) => {
    const _imo = vesselId ? dataState.uiState.vessels[vesselId].slug : imo;
    return {
      etd: dataState.data[_imo]?.ATD?.formattedValue,
      eta: dataState.data[_imo]?.ATA?.formattedValue,
    };
  });
const TraceSelector = () =>
  DeepSelector(
    dataState,
    SelectedVesselImo,
    (dataState: StoreDashboardState, activeImo: string) => {
      return activeImo ? dataState.data[activeImo].trace.values : [];
    }
  );

const PositionSelector = (vesselId: string = null) =>
  createSelector(
    dataState,
    variableSelector('COORD', vesselId),
    (state, coord) => {
      if (coord) return coord;
      return null;
    }
  );

const VesselIsStopped = (vesselId: string = null) =>
  DeepSelector(
    dataState,
    variableSelector('OPERATIONAL_STATUS', vesselId),
    (state, operationalStatus) => {
      const StoppedFlags = ['Stopped', 'N/A'];
      return StoppedFlags.includes(operationalStatus);
    }
  );
export {
  variableSelector,
  ProgressBarSelector,
  DownsampleVariableSelector,
  VesselDataSelector,
  TraceSelector,
  PositionSelector,
  MultipleVariableSelector,
  VesselIsStopped,
  variableObjectSelector,
  variablePreviewSelector,
};
