import { Injectable } from '@angular/core';
import { TraceConfig, RuleRange } from './trace.types';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {
  PositionHack,
  RangeCalculator,
  RangeValidator,
  RelativeValueCalculator,
} from './trace.utils';

const TRACE_LISTENER_KEY = 'TRACE' as const;
const TRACE_NEW_VALUE_LISTENER_KEY = 'TRACE_NEW_VAL' as const;
@Injectable()
export class TraceService {
  public offsetDays: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public ActiveTraceConfig$: BehaviorSubject<TraceConfig> = new BehaviorSubject(
    null
  );

  readonly _listeners = {
    [TRACE_LISTENER_KEY]: TRACE_LISTENER_KEY,
    [TRACE_NEW_VALUE_LISTENER_KEY]: TRACE_NEW_VALUE_LISTENER_KEY,
  };

  public _vesselImo = '';
  public traceConfiguration: TraceConfig[];
  private activeTraceConfig: TraceConfig;

  public Initialize(defaultTraceConfig: TraceConfig[]) {
    this.traceConfiguration = defaultTraceConfig;
    this.setActiveTraceConfig(1);
  }

  public setVesselImo(imo: string) {
    this._vesselImo = imo;
    return this;
  }

  public setActiveTraceConfig(id: number) {
    this.activeTraceConfig = this.traceConfiguration.find((t) => t.id == id);
    this.ActiveTraceConfig$.next(this.activeTraceConfig);
    return;
  }

  //we use this to update the rxjs service and show the ranges that are missing when we have relative configuration
  private extendActiveTraceConfig(config: Partial<TraceConfig>) {
    this.ActiveTraceConfig$.next({
      ...this.activeTraceConfig,
      ...config,
    });
  }

  public UpdateDashboardLine(
    traceConfig: TraceConfig,
    _positionData: string[],
    _templateData: string[]
  ) {
    const positionData = _positionData;

    const templateData = _templateData;

    if (!templateData || templateData.length === 0) return;

    let finalData: string[] = [];

    let relativeCustomRange: RuleRange[] = [];

    if ('range' in traceConfig) {
      finalData = templateData.map((d) =>
        RangeCalculator(traceConfig.range, d)
      );
    }
    if ('relativeValues' in traceConfig) {
      const { range, colors } = RelativeValueCalculator(
        traceConfig.relativeValues.colorRanges,
        templateData.map((d) => Number(d)),
        traceConfig.relativeValues.minIncrementRange
      );
      finalData = colors;
      relativeCustomRange = range;
      this.extendActiveTraceConfig({ range });
    }

    //get given template dataasd
    //for each template datum we need to change it to color

    //if the config is saying that we need to find the values and assign the color. We need to update the trace so we can show it to the user
    const mergeData = positionData.map((pos, i) => {
      return PositionHack(pos, finalData[i]);
    });
    return { traceData: mergeData, relativeCustomRange };
  }

  addNewValueInLine(
    traceConfig: TraceConfig,
    vals: { position: string; template: string }
  ): string | null {
    const newPosVal = vals.position;
    const newTemplateVal = vals.template;

    if (!newPosVal || !newTemplateVal) return null;

    let newPos = null;

    if ('range' in traceConfig) {
      const newTemplateColor = RangeCalculator(
        traceConfig.range,
        newTemplateVal
      );

      newPos = PositionHack(newPosVal, newTemplateColor);
    }

    if ('relativeValues' in traceConfig) {
      newPos = PositionHack(newPosVal, this.GetColorFromConfig(newTemplateVal));
    }
    return newPos;
  }

  private GetColorFromConfig(value: unknown): string {
    const config = this.ActiveTraceConfig$.value;
    const color = config.range
      .map((r) => RangeValidator(r, value as string))
      .filter((c) => c)[0];
    return color;
  }
}
