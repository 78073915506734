import { Injectable } from '@angular/core';
import { Position } from '../../../shared/modules/dynamic-dashboards/dashboard-library/types/map.types';
import {
  CircleMarker,
  DivIcon,
  LatLng,
  Marker,
  Polyline,
  PolylineOptions,
  divIcon,
  marker,
} from 'leaflet';
import { LineOpts } from '@widgets/map-widget/services/map-api.service';

@Injectable({
  providedIn: 'root',
})
export class MapUtilsService {
  private lineOpts: Partial<PolylineOptions> = {
    weight: 3,
    smoothFactor: 12,
    // dashArray: '5, 5',
    // dashOffset: '2',
    color: 'white',
    className: 'polyline',
    lineCap: 'round',
    lineJoin: 'round',
  };

  public HtmlMarker(
    position: LatLng,
    iconSize: [number, number] = [30, 20],
    html: HTMLElement,
    opts: { draggable?: boolean } = {}
  ) {
    const svgDirectionalIcon: DivIcon = divIcon({
      html,
      className: 'origin-center',
      iconSize,
      // iconAnchor: [18, 0],
    });
    return marker(position, {
      icon: svgDirectionalIcon,
      draggable: !!opts.draggable,
    });
  }
  public directionalMarker(
    position: LatLng,
    svgIcon: string | HTMLElement,
    rotationAngle = 0,
    opts: { draggable?: boolean } = {}
  ): Marker {
    const svgDirectionalIcon: DivIcon = divIcon({
      html: svgIcon,
      className: 'origin-center',
      iconSize: [30, 20],
      // iconAnchor: [18, 0],
    });
    return marker(position, {
      icon: svgDirectionalIcon,
      rotationAngle,
      draggable: !!opts.draggable,
    });
  }
  public circleMarker(position: LatLng): CircleMarker {
    return new CircleMarker(position, {
      radius: 8,
      fillOpacity: 1,
      opacity: 1,
      color: '#3176b7',
      fillColor: 'Blue',
      weight: 1,
    });
  }

  public labelMarker(id: string, position: LatLng, title = 'N/A'): Marker {
    return new Marker(position, {
      icon: new DivIcon({
        className: '',
        html: `<div id="${id}" class="marker-title w-64">${title}</div>`,
        iconAnchor: [30, 28],
      }),
    });
  }

  public line(
    className: string,
    _points: Position[],
    color: string,
    opts?: LineOpts & Partial<PolylineOptions>
  ): Polyline {
    const classes = [className];

    if (opts?.animate) classes.push('animate-dash-svg');

    if (opts?.clickableLine) classes.push('clickable-line');

    const routePointList = _points.map((item) => new LatLng(...item));

    const line = new Polyline(routePointList, {
      ...this.lineOpts,
      color,
      className: classes.join(' '),
      ...opts,
      smoothFactor: 1,
    });

    return line;
  }
}
